export const deadlines = [
    {
        value: 0,
        months: 1/6,
    },
    {
        value: 1,
        months: 1/2
    },
    {
        value: 2,
        months: 1,
    },
    {
        value: 3,
        months: 3
    },
    {
        value: 4,
        months: 6
    },
    {
        value: 5,
        months: 12,
    },
    {
        value: 6,
        months: 18
    },
    {
        value: 7,
        months: 24
    },
    {
        value: 8,
        months: 36,
    },
    {
        value: 9,
        months: 48
    },
    {
        value: 10,
        months: 60
    },
    {
        value: 11,
        months: 72,
    }
];
