import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';

import Accounts from './Accounts';
import Carla from './Carla';
import Colors from '../../../../constants/Colors';
import { formatNumberAsCurrency } from '../../../logic/form';
import { makeStyles } from '@material-ui/core/styles';

const mapComponentToName = {
  insurance: 'Seguro',
  administration: 'Administración',
  technology: 'Tecnología',
  iva: 'IVA',
  interest: 'Interés'
};

const getDeadline = (months) => {
  if (months <= 1) return `${months * 30} días`;
  else return `${months} meses`;
};

export const CreditDetails = ({
  form,
  data,
  selectedAccount,
  setSelectedAccount,
  accountNumber,
  setAccountNumber,
  automaticDebit,
  setAutomaticDebit,
  handleAccept,
  basicInfo,
  setForm
}) => {
  let { isInterested, amount, amountChanged, interest, credit, fee, feeComponents, deadline } = data;
  const [interestedInNew, setInterestedInNew] = useState(false);
  const [validationDone, setValidationDone] = useState(false);
  const [validatedIdentity, setValidatedIdentity] = useState(false);
  const [acceptVirtualCreditAdmin, setAcceptVirtualCreditAdmin] = useState(false);
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" width="100%">
      {((isInterested && !amountChanged) || (interestedInNew && amountChanged)) && (
        <Typography component="h4" variant="h5" align="center" gutterBottom className={classes.gray}>
          ¡Felicitaciones, tu solicitud para el crédito es viable!
        </Typography>
      )}
      {!interestedInNew && amountChanged && (
        <React.Fragment>
          <Typography variant="h6" align="center" gutterBottom className={classes.gray}>
            <strong>De acuerdo a tu perfil financiero te podemos ofrecer el siguiente crédito:</strong>
          </Typography>
        </React.Fragment>
      )}
      <hr />
      <Card className={classes.card}>
        <CardHeader
          className={classes.header}
          title={
            credit.image ? (
              <img alt={`${credit.name} logo`} src={credit.image} className={classes.logo} />
            ) : (
              <Typography className={classes.title} variant="h6">
                {credit.name}
              </Typography>
            )
          }
        />
        <CardContent>
          {fee > 0 && (
            <React.Fragment>
              <Typography variant="h6" align="center" colSpan={2}>
                <strong>Detalles del crédito</strong>
              </Typography>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <strong>Plazo</strong>
                    </TableCell>
                    <TableCell align="right">
                      {getDeadline(deadline)}
                      {amountChanged && amount <= 1000000 && '*'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <strong>Interés {!isInterested && 'desde'} (E.A)</strong>
                    </TableCell>
                    <TableCell align="right">{interest}%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <strong>Monto solicitado</strong>
                    </TableCell>
                    <TableCell align="right">
                      {formatNumberAsCurrency(amount)}
                      {amountChanged ? '**' : ''}
                    </TableCell>
                  </TableRow>
                  {Object.keys(feeComponents).map((key) => (
                    <TableRow key={key}>
                      <TableCell component="th" scope="row">
                        {mapComponentToName[key]}
                      </TableCell>
                      <TableCell align="right">{formatNumberAsCurrency(feeComponents[key])}**</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <strong>{deadline <= 1 ? 'Total a pagar' : 'Cuota mensual'}</strong>
                    </TableCell>
                    <TableCell align="right">
                      {formatNumberAsCurrency(fee)}
                      {!isInterested ? '*' : ''}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {amountChanged && amount <= 1000000 && (
                <Typography variant="body2" component="p" align="center">
                  * El plazo cambió al máximo que puede ofrecerte la entidad en este momento
                </Typography>
              )}
              {!isInterested && (
                <Typography variant="body2" component="p" align="center">
                  * Es un valor estimado y puede cambiar
                </Typography>
              )}
              <Typography variant="body2" component="p" align="center">
                ** Estos valores son cobrados mensualmente
              </Typography>
              {amountChanged && (
                <Typography variant="body2" component="p" align="center">
                  *** El monto cambió al máximo que puede prestarte la entidad en este momento
                </Typography>
              )}
              <br />
            </React.Fragment>
          )}
          {credit.policy &&
            credit.policy.accounts &&
            credit.redirectLink === 'direct' &&
            (!amountChanged || (amountChanged && interestedInNew)) && (
              <Accounts
                accounts={credit.policy.accounts}
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
                accountNumber={accountNumber}
                setAccountNumber={setAccountNumber}
                automaticDebit={automaticDebit}
                setAutomaticDebit={setAutomaticDebit}
              />
            )}
          {console.log("Int: "+isInterested+", valid "+validationDone+" ,ValidId: "+validatedIdentity)}
          {!isInterested && !validationDone && !validatedIdentity && (
            <div className={classes.virtualCreditAdmin}>
              <FormControlLabel
                labelPlacement="end"
                control={
                  <Checkbox
                    type="checkbox"
                    color="primary"
                    checked={acceptVirtualCreditAdmin}
                    onChange={(event) => setAcceptVirtualCreditAdmin(event.target.checked)}
                  />
                }
                label="Acepto tener un administrador de créditos virtual"
              />
            </div>
          )}
        </CardContent>
        <CardActions disableSpacing className={classes.actions}>
          {amountChanged && !interestedInNew && (
            <Button
              target="_blank"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => setInterestedInNew(true)}
            >
              Me interesa
            </Button>
          )}
          {((isInterested &&
            ((credit.redirectLink === 'direct' && selectedAccount.number) || credit.redirectLink === 'no-redirect')) ||
            (!isInterested && validatedIdentity)) && (
            <Button
              target="_blank"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleAccept}
            >
              {isInterested ? 'Confirmar' : 'Me interesa'}
            </Button>
          )}
          {!isInterested && !validationDone && !validatedIdentity && acceptVirtualCreditAdmin && (
            <div className={classes.carlaButtonDiv}>
              <Typography align="center">Para seguir por favor ayúdanos a verificar tu identidad</Typography>
              <Carla
                form={form}
                data={data}
                setValidatedIdentity={setValidatedIdentity}
                setValidationDone={setValidationDone}
                basicInfo={basicInfo}
                setForm={setForm}
              />
            </div>
          )}
          {!isInterested && validationDone && !validatedIdentity && (
            <Typography>Lo sentimos, no fue posible verificar tu identidad.</Typography>
          )}
        </CardActions>
      </Card>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    flex: '1 1 100%'
  },
  logo: {
    maxHeight: '4vh',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '6vh'
    },
    maxWidth: '80%'
  },
  card: {
    width: '94%',
    marginBottom: '5%'
  },
  actions: {
    backgroundColor: Colors.lightGray,
    justifyContent: 'center'
  },
  header: {
    color: 'black',
    backgroundColor: Colors.lightGray,
    textAlign: 'center',
    padding: '2% 0 1%'
  },
  gray: {
    color: '#666666'
  },
  carlaButtonDiv: {
    display: 'flex',
    flexDirection: 'column'
  },
  virtualCreditAdmin: {
    textAlign: 'center'
  }
}));
