import Colors from '../../constants/Colors';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const Footer = () => {
  const classes = useStyles();

  return <div className={classes.container}></div>;
};

const useStyles = makeStyles((theme) => ({
  container: {
    color: 'white',
    backgroundColor: Colors.primary,
    padding: '2% 10%',
    maxWidth: '100%',
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '10vh',
      paddingBottom: '10vh'
    }
  },
  fmLogo: {
    maxHeight: '22px',
    maxWidth: '100%'
  },
  linkText: {
    marginRight: '5px',
    textDecoration: 'none',
    color: Colors.white
  },
  bottomLink: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    textDecoration: 'none'
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'white',
    transition: '0.3s',
    '&:hover': {
      color: Colors.yellow
    }
  }
}));

export default Footer;
