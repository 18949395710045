import { deadlines } from '../data/sliders';

const rangeValidation = (variableLocation, parameters, data) => {
    let variable = getVariable(variableLocation, data);
    if (variable &&
        (parameters.min === null || parameters.min <= variable) &&
        (parameters.max === null || parameters.max >= variable)) {
        return true;
    }
    return false;
};

const lessThanValidation = (variableLocation, parameters, data) => {
    let variable = getVariable(variableLocation, data);
    if (variable && variable <= parameters.value) {
        return true;
    }
    return false;
};

const businessTypeValidation = (variableLocation, parameters, data) => {
    let variable = getVariable(variableLocation, data);
    if (variable && parameters.validIdentificationTypes.includes(variable)) {
        return true;
    }
    return false;
};

const equalityValidation = (variableLocation, parameters, data) => {
    let variable = getVariable(variableLocation, data);
    if (variable && variable.hasOwnProperty("name")) variable = variable.name;
    if (variable && parameters.value === variable) {
        return true;
    }
    return false;
};

const equalityBooleanValidation = (variableLocation, parameters, data) => {
    let variable = getVariable(variableLocation, data);
    if (parameters.value === variable) {
        return true;
    }
    return false;
};

const isInSet = (variableLocation, parameters, data) => {
    let variable = getVariable(variableLocation, data);
    if (variable && parameters.set.includes(variable)) {
        return true;
    }
    return false;
};

const isNotInSet = (variableLocation, parameters, data) => {
    let variable = getVariable(variableLocation, data);
    if (variable && !parameters.set.includes(variable)) {
        return true;
    }
    return false;
};

const minimumAntiquityMonths = (variableLocation, parameters, data) => {
    const currentDate = new Date();
    let maxDate = new Date(currentDate.setMonth(currentDate.getMonth() - parameters.months))
    let variable = getVariable(variableLocation, data);
    if (variable && new Date(variable) <= maxDate) {
        return true;
    }
    return false;
}

const economicActivityValidation = (variableLocation, parameters, data) => {
    let variable = getVariable(variableLocation, data);
    if (variable && parameters.validActivities.includes(variable)) {
        return true;
    }
    return false;
};

const ageValidation = (variableLocation, parameters, data) => {
    const currentDate = new Date().getTime();
    let variable = getVariable(variableLocation, data);
    const valueEntered = new Date(variable).getTime();
    if (variable && (currentDate - valueEntered) >= (parameters.min * 31536000000) && (currentDate - valueEntered) <= (parameters.max * 31536000000)) {
        return true;
    }
    return false;
}

const transportTypeValidation = (variableLocation, parameters, data) => {
    let variable = getVariable(variableLocation, data)
    if (variable && parameters.validTransportTypes.includes(variable)) {
        return true;
    }
    return false;
};

const minimalExperienceValidation = (variableLocation, parameters, data) => {
    let variable = getVariable(variableLocation, data);
    if (!variable) return false;
    variable = parseInt(variable.split("-")[0].split(" ")[0]);
    if (variable && variable >= parameters.minimalExperience) {
        return true;
    }
    return false;
};

const maxVehicleAntiquityValidation = (variableLocation, parameters, data) => {
    let variable = getVariable(variableLocation, data);
    if (!variable || (variable && parameters.validVehicleAntiquity <= variable)) {
        return true;
    }
    return false;
};

const newVehicleValidation = (variableLocation, parameters, data) => {
    let variable = getVariable(variableLocation, data);
    if (variable && parameters.validVehicleAntiquity.includes(variable)) {
        return true;
    }
    return false;
};

const creditDeadlineValidation = (variableLocation, parameters, data) => {
    let variable = getVariable(variableLocation, data);
    if (variable >= 0 && variable < deadlines.length) {
        variable = deadlines[variable].months;
    } else return false;
    if (Array.isArray(parameters.maxDeadline)) parameters.maxDeadline = parseInt(parameters.maxDeadline[0])
    if (variable && parameters.maxDeadline >= variable) {
        return true;
    }
    return false;
};

const maxAmountByPercentageValidation = (variableLocation, parameters, data) => {
    let variable = getVariable(variableLocation, data);
    let vehicleValue = getVariable(parameters.vehicleValue, data);
    if (variable && variable <= (vehicleValue * parameters.maxAmountPercentage / 100)) {
        return true;
    }
    return false;
};

const identificationTypeValidation = (variableLocation, parameters, data) => {
    let variable = getVariable(variableLocation, data);
    if (variable && parameters.validIdentificationTypes.includes(variable)) {
        return true;
    }
    return false;
};

const minimumIncomeValidation = (variableLocation, parameters, data) => {
    let variable = getVariable(variableLocation, data);
    if (variable && variable >= parameters.min) {
        return true;
    }
    return false;
};

const amountValidation = (variableLocation, parameters, data) => {
    let variable = getVariable(variableLocation, data);
    if (variable && variable >= parameters.min && variable <= parameters.max) {
        return true;
    }
    return false;
};

const getVariable = (variableLocation, data) => {
    const properties = variableLocation.split('.');
    let variable = data;
    properties.forEach((prop) => {
        if (variable && variable.hasOwnProperty(prop)) {
            variable = variable[prop];
        }
        else {
            variable = null;
        }
    })
    return variable;
}

export default {
    equalityValidation,
    rangeValidation,
    isInSet,
    isNotInSet,
    minimumAntiquityMonths,
    economicActivityValidation,
    ageValidation,
    transportTypeValidation,
    minimalExperienceValidation,
    maxVehicleAntiquityValidation,
    newVehicleValidation,
    creditDeadlineValidation,
    maxAmountByPercentageValidation,
    minimumIncomeValidation,
    lessThanValidation,
    amountValidation,
    identificationTypeValidation,
    businessTypeValidation,
    equalityBooleanValidation
}; 