import { Backdrop, Button, CircularProgress, Container, TextField, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { getApi, postApi } from '../api/apiManager';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Colors from '../../constants/Colors';
import CssBaseline from '@material-ui/core/CssBaseline';
import CurrencyFormat from '../common/CurrencyFormat';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { validateAlphabeticInput } from '../../controllers/validators';

const PaymentPage = ({ creditNumber }) => {
  const classes = useStyles();
  const [paymentValue, setPaymentValue] = useState(100000);
  const [identification, setIdentification] = useState(null);
  const [identificationError, setIdentificationError] = useState(true);
  const [nameError, setNameError] = useState(true);
  const [paymentValueError, setPaymentValueError] = useState(false);
  const [minimunFeeError, setMinimunFeeError] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedBank, setSelectedBank] = useState({});
  const [identificationType, setIdentificationType] = useState({});
  const [identificationTypeError, setIdentificationTypeError] = useState(true);
  const [name, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [userIp, setUserIp] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [minimunFee, setMinimunFee] = useState(0);
  const [retry, setRetry] = useState(false);

  const cancelPayment = () => {
    setRedirect(true);
  };

  const renderRedirect = () => {
    if (redirect) return <Redirect to="/" />;
    if (retry) return <Redirect to={`/pago/${creditNumber}`} />;
  };

  const calculateMinimunFee = (selectedLender) => {
    const amount = selectedLender.amount;
    const anualInterest = selectedLender.interestResult.interest;
    const term = selectedLender.term;
    let feeComponentsValue = 0;
    Object.keys(selectedLender.feeComponents).forEach((key) => {
      if (key !== 'interest') feeComponentsValue = feeComponentsValue + selectedLender.feeComponents[key];
    });
    const monthlyInterest = Math.pow(1 + anualInterest / 100, 1 / 12) - 1;
    const numerator = amount * monthlyInterest;
    const denominator = 1 - Math.pow(1 + monthlyInterest, -term);
    return Math.ceil(numerator / denominator + feeComponentsValue);
  };

  const getCreditInfo = useCallback(async () => {
    try {
      setLoading(true);
      const data = await postApi('funnel/find-credit', {
        number: creditNumber,
        entity: 'Bancatek',
        numberType: 'Número de crédito'
      });
      const selectedCredit = data[0].selectedLender;
      const minimumFeeNumber = calculateMinimunFee(selectedCredit);
      setMinimunFee(minimumFeeNumber);
      setPaymentValue(minimumFeeNumber);
    } catch (err) {
      console.log('Error', err);
    }
  }, [creditNumber]);

  const getBankList = async () => {
    try {
      setLoading(true);
      const data = await getApi('ach/bank-list');
      setBankList(data);
    } catch (err) {
      console.log('Error', err);
    }
  };
  const getIp = () => {
    fetch(`https://api.ipify.org?format=json`, {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setUserIp(data.ip);
      });
  };

  useEffect(() => {
    getCreditInfo();
    getBankList();
    getIp();
  }, [creditNumber, getCreditInfo]);

  useEffect(() => {
    if (bankList.length > 0 && minimunFee != 0) setLoading(false);
  }, [bankList, minimunFee]);

  const setPayment = (event) => {
    const value = event.target.value;
    setPaymentValue(value);
    if (!value || value === 0) {
      setPaymentValueError(true);
      setMinimunFeeError(false);
    } else {
      setPaymentValueError(false);
      if (value < minimunFee) setMinimunFeeError(true);
      else setMinimunFeeError(false);
    }
  };

  const retryPayment = () => {
    setRetry(true);
  };

  const setIdentificationValue = (event) => {
    const value = event.target.value;
    setIdentification(value);
    if (!value || value === 0) setIdentificationError(true);
    else setIdentificationError(false);
  };

  const setNameValue = (event) => {
    const value = event.target.value;
    setName(value);
    const error = !validateAlphabeticInput(value);
    if (!value || value === '') setNameError(true);
    else setNameError(false || error);
  };

  const doPayment = async () => {
    try {
      setLoading(true);
      const userType = identificationType === 'NIT' ? '1' : '0';
      const data = await postApi('ach/transaction-payment', {
        entityUrl: `${window.location.protocol}//${window.location.host}`,
        selectedBank: selectedBank,
        paymentDescription: `Pago de cuota del crédito No. ${creditNumber}`,
        amountValue: paymentValue,
        vatValue: '0',
        userType: userType,
        entity: 'Bancatek',
        name: name,
        identificationType: identificationType,
        identification: identification,
        userIp: userIp,
        creditNumber: creditNumber,
        serviceCode: '1002'
      });
      console.log(data.returnCode.value);
      if (data.returnCode.value === 'SUCCESS') {
        window.location.href = data.bankurl;
      } else if (data.returnCode.value === 'FAIL_EXCEEDEDLIMIT') {
        setLoading(false);
        setErrorMessage(
          'El monto de la transacción excede los límites establecidos en PSE para Bancatek, por favor comunícate con Bancatek al correo info@bancatek.com.'
        );
      } else if (data.returnCode.value === 'FAIL_BANKUNREACHEABLE') {
        setLoading(false);
        setErrorMessage(
          'La entidad financiera no puede ser contactada para iniciar la transacción, por favor selecciona otra o intenta más tarde. También puedes comunicarte con Bancatek al correo info@bancatek.com.'
        );
      } else if (
        data.returnCode.value === 'FAIL_ENTITYNOTEXISTSORDISABLED' ||
        data.returnCode.value === 'FAIL_BANKNOTEXISTSORDISABLED,' ||
        data.returnCode.value === 'FAIL_SERVICENOTEXISTS' ||
        data.returnCode.value === 'FAIL_INVALIDAMOUNT' ||
        data.returnCode.value === 'FAIL_INVALIDSOLICITDATE' ||
        data.returnCode.value === 'NOTCONFIRMEDBYBANK' ||
        data.returnCode.value === 'FAIL_CANNOTGETCURRENTCYCLE' ||
        data.returnCode.value === 'FAIL_ACCESSDENIED' ||
        data.returnCode.value === 'FAIL_TIMEOUT' ||
        data.returnCode.value === 'FAIL_DESCRIPTIONNOTFOUND'
      ) {
        setErrorMessage(
          'No se pudo crear la transacción, por favor intenta más tarde o comunicate con Bancatek al correo info@bancatek.com.'
        );
      } else {
        setErrorMessage(
          'No se pudo crear la transacción, por favor intenta más tarde o comunícate con Bancatek al correo info@bancatek.com.'
        );
      }
    } catch (err) {
      console.log('Error', err);
    } finally {
      setLoading(false);
    }
  };

  const selectBank = (event, value) => {
    if (value !== null) setSelectedBank(value);
    else setSelectedBank({});
  };

  const selectIdentificationType = (event, value) => {
    if (value === null) setIdentificationTypeError(true);
    else setIdentificationTypeError(false);
    setIdentificationType(value);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header />
      <div component="main" className={classes.main}>
        <Container className={classes.container} maxWidth={false}>
          {renderRedirect()}
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <CssBaseline />
          {!loading && (
            <div>
              <Typography className={classes.mainTitle}>Realiza tu pago a través de PSE</Typography>
              <Typography>
                <strong>Descripción del pago:</strong> Pago de cuota del crédito No. {creditNumber}
              </Typography>
              <Autocomplete
                noOptionsText="No hay opciones"
                className={classes.select}
                options={['Cédula de ciudadanía', 'Cédula de extranjería', 'NIT']}
                getOptionLabel={(option) => option}
                onChange={selectIdentificationType}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="userType"
                    value={identificationType || ''}
                    label="Tipo de indentificación"
                    fullWidth
                    error={identificationTypeError}
                    helperText={identificationTypeError ? 'Tienes que seleccionar un tipo de persona' : null}
                  />
                )}
              />
              <TextField
                name="identification"
                className={classes.inputText}
                type="number"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                label="Número de identificación"
                autoComplete="off"
                value={identification || ''}
                error={identificationError}
                helperText={identificationError ? 'Revisa que sea un número válido' : null}
                onChange={(value) => setIdentificationValue(value)}
              />
              <br />
              <TextField
                name="value"
                className={classes.inputText}
                label="Nombre del titular"
                autoComplete="off"
                value={name || ''}
                error={nameError}
                helperText={nameError ? 'Revisa que no este vacío y que solo contenga letras' : null}
                onChange={(event) => setNameValue(event)}
              />
              <Autocomplete
                noOptionsText="No hay opciones"
                className={classes.select}
                options={bankList}
                getOptionLabel={(option) => option.financialInstitutionName}
                onChange={selectBank}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="identificationType"
                    value={selectedBank.financialInstitutionName || ''}
                    label="Entidad bancaria"
                    fullWidth
                  />
                )}
              />
              <TextField
                name="value"
                className={classes.inputText}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                label="Valor a pagar"
                autoComplete="off"
                value={paymentValue || ''}
                error={paymentValueError || minimunFeeError}
                helperText={
                  paymentValueError
                    ? 'Revisa que sea un número válido'
                    : minimunFeeError
                    ? 'El valor tiene que ser por lo menos la cuota miníma'
                    : null
                }
                onChange={(value) => setPayment(value)}
                InputProps={{
                  inputComponent: CurrencyFormat
                }}
              />
              {Object.keys(selectedBank).length > 0 &&
                !minimunFeeError &&
                !paymentValueError &&
                !nameError &&
                !identificationTypeError &&
                !identificationError && (
                  <div>
                    <img
                      className={classes.logo}
                      src="https://fm-cdn-bckt.s3.amazonaws.com/img/logo_380.png"
                      alt="PSE Logo"
                      height="190"
                      width="185"
                    />
                    <Button onClick={doPayment} color="primary" className={classes.button}>
                      Ir a la entidad bancaria
                    </Button>
                  </div>
                )}
              {errorMessage !== null && <Typography className={classes.error}>{errorMessage}</Typography>}
              {errorMessage !== null && (
                <div className={classes.buttonGrid}>
                  <Button onClick={() => retryPayment()} className={classes.button} variant="contained">
                    Reintentar pago
                  </Button>
                </div>
              )}
              <div className={classes.buttonGrid}>
                <Button onClick={() => cancelPayment()} className={classes.button} variant="contained">
                  Salir
                </Button>
              </div>
            </div>
          )}
        </Container>
        <footer className={classes.footer}>
          <Footer />
        </footer>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(0),
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  },
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    marginTop: '-1%',
    padding: '5% 15% 5%',
    [theme.breakpoints.down('sm')]: {
      padding: '5% 10%'
    }
  },
  mainTitle: {
    color: Colors.primary,
    textAlign: 'left',
    fontSize: '2.3em',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.8em',
      textAlign: 'center'
    }
  },
  error: {
    color: Colors.secondary,
    textAlign: 'left',
    fontSize: '1.5em',
    fontWeight: 200,
    marginTop: '2%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.6em'
    }
  },
  logo: {
    marginTop: '1%'
  },
  backdrop: {
    color: Colors.primary
  },
  inputText: {
    width: '30%',
    minWidth: '300px',
    margin: '1% 0'
  },
  select: {
    width: '30%',
    minWidth: '300px',
    margin: '1% 0'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    backgroundColor: Colors.secondary,
    color: 'white',
    marginBottom: '1%'
  },
  buttonGrid: {
    textAlign: 'center'
  },
  footer: {
    marginTop: 'auto'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  }
}));

export default PaymentPage;
