import 'moment/locale/es';

import React, { useCallback, useEffect } from 'react';
import {
  validateAlphabeticInput,
  validateEmailInput,
  validateNumericInput,
  validatePhoneInput,
} from '../../../controllers/validators';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TermsAndConditions from '../TermsAndConditions';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DatePicker } from '@material-ui/pickers';
import cities from '../../../data/cities';
import corporateBusinessType from '../../../data/corporateBusinessType';
import corporateEconomicActivityTypes from '../../../data/corporateEconomicActivityTypes';

const classes = {
  indicator: {
    marginRight: 10,
  },
};

const initialFieldCount = 10;

export default function BasicInfoCorporate(props) {
  let {
    attribute,
    data,
    errors,
    handleChange,
    handleCustomChange,
    setTotalFields,
    setCompletedFields,
    partOfSummary,
  } = props;

  const updateCompletedFieldCount = useCallback(() => {
    let completed = 0;
    if (data.businessName && !errors.businessName) completed++;
    if (data.contactName && !errors.contactName) completed++;
    if (data.identification && !errors.identification) completed++;
    if (data.identificationType && !errors.identificationType) completed++;
    if (data.residenceCity && !errors.residenceCity) completed++;
    if (data.email && !errors.email) completed++;
    if (data.phone && !errors.phone) completed++;
    if (data.specialCondition && !errors.specialCondition) completed++;
    if (data.economicActivity && !errors.economicActivity) completed++;
    if (data.termsAndConditions && !errors.termsAndConditions) completed++;
    if (data.businessType && !errors.businessType) completed++;
    if (data.startDate) completed++;
    setCompletedFields(completed);
  }, [data, errors, setCompletedFields]);

  const updateTotalFieldCount = useCallback(() => {
    let fieldCount = initialFieldCount;
    if (data.identificationType !== undefined) {
      fieldCount += 1;
      if (data.identificationType === 'NIT') fieldCount += 1;
    }
    setTotalFields(fieldCount);
  }, [setTotalFields, data]);

  useEffect(() => {
    updateTotalFieldCount();
    updateCompletedFieldCount();
  }, [props, updateTotalFieldCount, updateCompletedFieldCount]);

  const changeData = (name, value, error) => {
    if (partOfSummary) {
      handleCustomChange(attribute, name, value, error);
    } else handleChange(name, value, error);
  };

  const handleName = (event) => {
    const error = !validateAlphabeticInput(event.target.value);
    changeData(event.target.name, event.target.value, error);
  };

  const handleEmail = (event) => {
    const error = !validateEmailInput(event.target.value);
    changeData(event.target.name, event.target.value, error);
  };

  const handleStartDate = (date) => {
    changeData('startDate', date.toDate().toISOString(), false);
  };

  const handleIdentification = (event) => {
    const error = !validateNumericInput(event.target.value);
    changeData(event.target.name, event.target.value, error);
  };

  const handlePhone = (event) => {
    const error = !validatePhoneInput(event.target.value);
    changeData(event.target.name, event.target.value, error);
  };

  const handleResidenceCity = (event, value) => {
    changeData('residenceCity', value, false);
  };

  const handleBusinessType = (event, value) => {
    changeData('businessType', value, false);
  };

  const handleIDType = (event, value) => {
    if (value === null || value !== 'NIT') handleBusinessType(null)
    changeData('identificationType', value, false);
  };

  const handleSelect = (event) => {
    changeData(event.target.name, event.target.value, false);
  };

  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <React.Fragment>
          {!partOfSummary && (
            <MediaQuery maxDeviceWidth={760}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" align="center">
                  Ingresa los datos de la empresa
                </Typography>
              </Grid>
            </MediaQuery>
          )}

          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="businessName"
              label="Nombre de la empresa"
              fullWidth
              value={data.businessName || ''}
              onChange={handleName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="contactName"
              label="Nombre de contacto"
              fullWidth
              value={data.contactName || ''}
              onChange={handleName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="email"
              name="email"
              type="email"
              label="Correo electrónico"
              fullWidth
              autoComplete="email"
              error={errors.email}
              helperText={errors.email ? 'Ingresa un correo válido' : null}
              onChange={handleEmail}
              value={data.email || ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="phone"
              name="phone"
              type="tel"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              label="Número de celular"
              fullWidth
              autoComplete="tel"
              error={errors.phone}
              helperText={errors.phone ? 'Ingresa un número válido' : null}
              onChange={handlePhone}
              value={data.phone || ''}
              InputProps={{
                startAdornment: <Chip label="+57" color="secondary" style={classes.indicator} />,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              required
              noOptionsText="No hay opciones"
              options={['NIT', 'Cédula de ciudadanía', 'Cédula de extranjería']}
              defaultValue={() => data.identificationType}
              getOptionLabel={(option) => option}
              onChange={handleIDType}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  id="identificationType"
                  value={data.identificationType || ''}
                  label="Tipo de identificación"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type="number"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              name="identification"
              label="Número de identificación"
              required
              fullWidth
              error={errors.identification}
              helperText={errors.identification ? 'Revisa que sea un número válido' : null}
              onChange={handleIdentification}
              value={data.identification || ''}
            />
          </Grid>
          {data.identificationType && data.identificationType === 'NIT' &&
          <Grid item xs={12} sm={6}>
          <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
            <DatePicker
              fullWidth
              autoOk
              clearable
              openTo="year"
              label="Fecha de constitución"
              value={data.startDate ? new Date(data.startDate) : null}
              onChange={(date) => handleStartDate(date)}
              maxDate={new Date()}
              format="LL"
            />
          </MuiPickersUtilsProvider>
        </Grid>
          }
          {data.identificationType && data.identificationType !== 'NIT' &&
          <Grid item xs={12} sm={6}>
          <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
            <DatePicker
              fullWidth
              autoOk
              clearable
              openTo="year"
              label="Fecha inicio de operación"
              value={data.startDate ? new Date(data.startDate) : null}
              onChange={(date) => handleStartDate(date)}
              maxDate={new Date()}
              format="LL"
            />
          </MuiPickersUtilsProvider>
        </Grid>
          }
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="none">
              <InputLabel>Condición especial</InputLabel>
              <Select
                fullWidth
                value={data.specialCondition || ''}
                name="specialCondition"
                onChange={handleSelect}
                label="¿Tienes alguna condición especial?"
              >
                <MenuItem value={'Ninguna'}>Ninguna</MenuItem>
                <MenuItem value={'Discapacidad'}>Personas en condición de discapacidad</MenuItem>
                <MenuItem value={'Desplazado'}>Desplazados / Víctimas del conflicto armado</MenuItem>
                <MenuItem value={'Relación comercial'}>Relación comercial con España</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="none">
              <InputLabel>Actividad económica</InputLabel>
              <Select
                fullWidth
                value={data.economicActivity || ''}
                name="economicActivity"
                onChange={handleSelect}
                label="Actividad económica"
              >
                {corporateEconomicActivityTypes.map((type, i) => {
                  return (
                    <MenuItem value={type.value} key={i} style={isMobile ? {overflow: 'scroll', fontSize: '0.8rem'}: {}}>
                      {type.description}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          {data.identificationType && data.identificationType === 'NIT' ?
            <Grid item xs={12} sm={6}>
              <Autocomplete
                required
                noOptionsText="No hay opciones"
                options={corporateBusinessType}
                getOptionLabel={(option) => option}
                defaultValue={() => data.businessType}
                onChange={handleBusinessType}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    id="businessType"
                    value={data.businessType || ''}
                    label="Tipo de sociedad"
                    fullWidth
                  />
                )}
              />
            </Grid>
            : null
          }
          <Grid item xs={12} sm={6}>
            <Autocomplete
              required
              noOptionsText="No hay opciones"
              options={cities}
              getOptionLabel={(option) => option}
              defaultValue={() => data.residenceCity}
              onChange={handleResidenceCity}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  autoComplete="off"
                  value={data.residenceCity || ''}
                  label="Ciudad ubicación empresa"
                  fullWidth
                />
              )}
            />
          </Grid>
          {data.identificationType && data.identificationType === 'NIT' &&
          <Grid item xs={12} sm={12} style={{ padding: 0 }}>
            <Grid container alignContent="space-between" justify="center" align="center">
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      type="checkbox"
                      color="primary"
                      name="isRepLegal"
                      checked={data.isRepLegal || false}
                      onChange={(event) => handleChange(event.target.name, event.target.checked, false)}
                    />
                  }
                  label="¿Eres el representante legal?"
                />
              </Grid>
            </Grid>
          </Grid>
          }
          {!partOfSummary && (
            <Grid item xs={12} sm={12} style={{ padding: 0 }}>
              <Grid container alignContent="space-between" justify="center" align="center">
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        type="checkbox"
                        color="primary"
                        name="termsAndConditions"
                        checked={data.termsAndConditions || false}
                        onChange={(event) => handleChange(event.target.name, event.target.checked, false)}
                      />
                    }
                  />
                </Grid>
                <Grid item>
                  <TermsAndConditions handleClick={() => handleChange('termsAndConditions', true, false)} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </React.Fragment>
      </Grid>
    </React.Fragment>
  );
}
