import React, { useEffect } from 'react';

import Colors from '../../../../constants/Colors';
import { makeStyles } from '@material-ui/core/styles';

const FinancialoMejor = window.FinancialoMejor;

const mapDocumentString = {
  'Cédula de ciudadanía': 'cc'
};

const Carla = ({ form, setValidatedIdentity, setValidationDone, basicInfo, setForm }) => {
  const classes = useStyles();

  useEffect(() => {
    const onStart = (error) => {
      if (error) {
        console.log('Failed', error);
      }
    };
    const onFinished = (error, transactionId, additionalData) => {
      console.log(additionalData, transactionId);
      if (error) {
        console.log(error);
      }
      setValidationDone(true);
      if (additionalData.basicInfo && !form.data.basicInfo.name) {
        if (additionalData.basicInfo.documentType === 'cc')
          additionalData.basicInfo.identificationType = 'Cédula de ciudadanía';
        form.data.basicInfo = additionalData.basicInfo;
        console.log(form.data.basicInfo, additionalData.basicInfo);
        setForm({ ...form });
      }
      if (additionalData.status === 'passed') setValidatedIdentity(true);
    };
    const element = document.getElementById('fm-button');
    let params = {
      primaryColor: Colors.primary,
      secondaryColor: Colors.secondary,
      buttonText: 'Verificar',
      buttonIcon:
        window.location.hostname === 'colsubsidio.financialomejor.com'
          ? 'https://colsubsidio.financialomejor.com/colsubsidio.png'
          : 'https://bancatek.com/favicon.png',
      emailSource: window.location.hostname === 'colsubsidio.financialomejor.com' ? 'Colsubsidio' : 'Bancatek'
    };

    if (basicInfo && basicInfo.identification) {
      params.clientInformation = {
        documentType: mapDocumentString[basicInfo.identificationType],
        identification: parseInt(basicInfo.identification),
        lastName: basicInfo.lastName,
        name: basicInfo.name,
        email: basicInfo.email,
        birthday: basicInfo.birthday,
        expeditionDate: basicInfo.expeditionDate,
        phone: parseInt(basicInfo.phone)
      };
    }

    FinancialoMejor &&
      FinancialoMejor.renderButton(element, process.env.REACT_APP_CARLA_KEY, onFinished, onStart, params);
  }, [basicInfo, setValidatedIdentity, setValidationDone]);

  const renderFmButton = () => {
    return <div id="fm-button" style={{ display: 'block' }} />;
  };

  return <div className={classes.fmButton}>{renderFmButton()}</div>;
};

const useStyles = makeStyles((theme) => ({
  fmButton: {
    margin: 'auto',
    padding: '1%'
  }
}));

export default Carla;
