const economicActivityTypes = [
    {
        name: 'Asalariado', detail: [
            'Término fijo' ,
            'Término indefinido' ,
            'Prestación de servicios' 
        ]
    },
    {
        name: 'Independiente', detail: [
            'Construcción' ,
            'Agricultura',
            'Transporte',
            'Comercio',
            'Rentista de Capital',
            'Industrial',
            'Servicios',
            'Profesional Independiente'
        ]
    },
    { name: 'Pensionado' }
]

export default economicActivityTypes;