import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import LandingPageRouter from './LandingPageRouter';
import Header from '../common/Header';
import Footer from '../common/Footer';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(7), 
    },
    marginBottom: theme.spacing(0),
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  },
  footer: {
    marginTop: 'auto',
  },
}));


const LandingPage = (props) => {

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <div component="main" className={classes.main} >
        <Header {...props} />
        <LandingPageRouter {...props} />
      </div>
      <footer className={classes.footer}>
        <Footer />
      </footer>
    </div>
  );
};

export default LandingPage;
