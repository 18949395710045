import 'moment/locale/es';

import React, { useCallback, useEffect } from 'react';
import {
  validateAlphabeticInput,
  validateEmailInput,
  validateNumericInput,
  validatePhoneInput,
  validateBirthday
} from '../../../controllers/validators';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import MediaQuery from 'react-responsive';
import TermsAndConditions from '../TermsAndConditions';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DatePicker } from '@material-ui/pickers';

const classes = {
  indicator: {
    marginRight: 10,
  },
};

const initialFieldCount = 8;

export default function BasicInfoQuicklink(props) {
  let {
    attribute,
    data,
    errors,
    handleChange,
    handleCustomChange,
    setTotalFields,
    setCompletedFields,
    partOfSummary,
  } = props;

  const updateCompletedFieldCount = useCallback(() => {
    let completed = 0;
    if (data.name && !errors.name) completed++;
    if (data.lastName && !errors.lastName) completed++;
    if (data.identification && !errors.identification) completed++;
    if (data.identificationType && !errors.identificationType) completed++;
    if (data.email && !errors.email) completed++;
    if (data.phone && !errors.phone) completed++;
    if (data.termsAndConditions && !errors.termsAndConditions) completed++;
    if (data.birthday && !errors.birthday) completed++;
    setCompletedFields(completed);
  }, [data, errors, setCompletedFields]);

  const updateTotalFieldCount = useCallback(() => {
    let fieldCount = initialFieldCount;
    setTotalFields(fieldCount);
  }, [setTotalFields]);

  useEffect(() => {
    updateTotalFieldCount();
    updateCompletedFieldCount();
  }, [props, updateTotalFieldCount, updateCompletedFieldCount]);

  const changeData = (name, value, error) => {
    if (partOfSummary) {
      handleCustomChange(attribute, name, value, error);
    } else handleChange(name, value, error);
  };

  const handleName = (event) => {
    const error = !validateAlphabeticInput(event.target.value);
    changeData(event.target.name, event.target.value, error);
  };

  const handleBirthday = (date) => {
    const error = !validateBirthday(date);
    changeData('birthday', date.toDate().toISOString(), error);
  };

  const handleExpeditionDate = (date) => {
    changeData('expeditionDate', date.toDate().toISOString(), false);
  };

  const handleEmail = (event) => {
    const error = !validateEmailInput(event.target.value);
    changeData(event.target.name, event.target.value, error);
  };

  const handleIdentification = (event) => {
    const error = !validateNumericInput(event.target.value);
    changeData(event.target.name, event.target.value, error);
  };

  const handlePhone = (event) => {
    const error = !validatePhoneInput(event.target.value);
    changeData(event.target.name, event.target.value, error);
  };

  const handleIDType = (event, value) => {
    changeData('identificationType', value, false);
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <React.Fragment>
          {!partOfSummary && (
            <MediaQuery maxDeviceWidth={760}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" align="center">
                  Ingresa tus datos personales
                </Typography>
              </Grid>
            </MediaQuery>
          )}
          {!partOfSummary && (
              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle1" align="center" style={{margin: '0 5%'}}>
                  Asegúrate de que tus datos aparezcan de la misma forma que en tu documento de identidad
                </Typography>
              </Grid>
          )}

          <Grid item xs={12} sm={6}>
            <TextField
              id="name"
              name="name"
              label="¿Cuáles son tus nombres?"
              fullWidth
              autoComplete="fname"
              value={data.name || ''}
              error={errors.name}
              helperText={errors.name ? 'Revisa que tu nombre solo tenga letras' : null}
              onChange={handleName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="lastName"
              name="lastName"
              label="¿Cuáles son tus apellidos?"
              fullWidth
              autoComplete="lname"
              error={errors.lastName}
              helperText={errors.lastName ? 'Revisa que tu apellido solo tenga letras' : null}
              onChange={handleName}
              value={data.lastName || ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              noOptionsText="No hay opciones"
              options={['Cédula de ciudadanía', 'Cédula de extranjería']}
              defaultValue={() => data.identificationType}
              getOptionLabel={(option) => option}
              onChange={handleIDType}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="identificationType"
                  value={data.identificationType || ''}
                  label="¿Cuál es el tipo de tu identificación?"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="identification"
              type="number"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              name="identification"
              label="¿Cuál es el número de tu identificación?"
              fullWidth
              error={errors.identification}
              helperText={errors.identification ? 'Revisa que sea un número válido' : null}
              onChange={handleIdentification}
              value={data.identification || ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
              <DatePicker
                fullWidth
                autoOk
                clearable
                openTo="year"
                name="expeditionDate"
                label="Fecha expedición documento"
                value={data.expeditionDate ? new Date(data.expeditionDate) : null}
                onChange={(date) => handleExpeditionDate(date)}
                maxDate={new Date()}
                format="LL"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
              <DatePicker
                fullWidth
                autoOk
                clearable
                openTo="year"
                name="birthday"
                label="Fecha de nacimiento"
                value={data.birthday ? new Date(data.birthday) : null}
                onChange={(date) => handleBirthday(date)}
                error={errors.birthday}
                helperText={errors.birthday ? 'Debes tener 18 años para pedir un crédito' : null}
                maxDate={new Date()}
                format="LL"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="email"
              name="email"
              type="email"
              label="¿Cómo es tu correo electrónico?"
              fullWidth
              autoComplete="email"
              error={errors.email}
              helperText={errors.email ? 'Ingresa un correo válido' : null}
              onChange={handleEmail}
              value={data.email || ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="phone"
              name="phone"
              type="tel"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              label="¿Cúal es tu número de celular?"
              fullWidth
              autoComplete="tel"
              error={errors.phone}
              helperText={errors.phone ? 'Ingresa un número válido' : null}
              onChange={handlePhone}
              value={data.phone || ''}
              InputProps={{
                startAdornment: <Chip label="+57" color="secondary" style={classes.indicator} />,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="sellerId"
              type="number"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              name="sellerId"
              label="ID Vendedor (Opcional)"
              fullWidth
              error={errors.sellerId}
              helperText={errors.sellerId ? 'Revisa que sea un número válido' : null}
              onChange={handleIdentification}
              value={data.sellerId || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container alignContent="space-between" justify="center" align="center">
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      type="checkbox"
                      color="primary"
                      name="isReported"
                      checked={data.isReported || false}
                      onChange={(event) => changeData(event.target.name, event.target.checked, false)}
                    />
                  }
                  label="¿Estás reportado en centrales de riesgo?"
                />
              </Grid>
            </Grid>
          </Grid>
          {!partOfSummary && (
            <Grid item xs={12} sm={12}>
              <Grid container alignContent="space-between" justify="center" align="center">
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        type="checkbox"
                        color="primary"
                        name="termsAndConditions"
                        checked={data.termsAndConditions || false}
                        onChange={(event) => handleChange(event.target.name, event.target.checked, false)}
                      />
                    }
                  />
                </Grid>
                <Grid item>
                  <TermsAndConditions handleClick={() => handleChange('termsAndConditions', true, false)} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </React.Fragment>
      </Grid>
    </React.Fragment>
  );
}
