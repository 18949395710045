import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function SuccessMessage({ type, name, basicInfo }) {
  return (
    <React.Fragment>
      {type === 'empresas' ? (
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom style={{ color: '#666666' }}>
            ¡Gracias por tu solicitud{` ${basicInfo.contactName ? basicInfo.contactName : basicInfo.name}`}!
          </Typography>
          <Typography variant="subtitle1" style={{ marginBottom: '5vh' }}>
            Te enviaremos la información de tu crédito empresarial al correo {basicInfo.email}.
          </Typography>
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom style={{ color: '#666666' }}>
            ¡Gracias por tu solicitud{` ${basicInfo.name}`}!
          </Typography>
          <Typography variant="subtitle1" style={{ marginBottom: '5vh' }}>
            Te enviaremos la información de tu crédito {type !== 'quicklink' && `de ${name}`} al correo{' '}
            {basicInfo.email}.
          </Typography>
        </div>
      )}
    </React.Fragment>
  );
}
