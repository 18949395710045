import React, { useState } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import PaymentDetail from './PaymentDetail';
import PaymentSearch from './PaymentSearch';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  main: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(0),
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  }
}));

const PaymentPage = () => {
  const classes = useStyles();
  const [creditNumber, setCreditNumber] = useState('');
  return (
    <div className={classes.root}>
      <CssBaseline />
      {creditNumber ? (
        <PaymentDetail creditNumber={creditNumber} />
      ) : (
        <PaymentSearch setCreditNumber={setCreditNumber} />
      )}
    </div>
  );
};

export default PaymentPage;
