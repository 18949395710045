import 'moment/locale/es';

import React, { useCallback, useEffect } from 'react';
import {
  validateAlphabeticInput,
  validateBirthday,
  validateEmailInput,
  validateNumericInput,
  validatePhoneInput
} from '../../../controllers/validators';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { DatePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MediaQuery from 'react-responsive';
import MenuItem from '@material-ui/core/MenuItem';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import TermsAndConditions from '../TermsAndConditions';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import cities from '../../../data/cities';
import { getStringDate } from '../../logic/form';

const classes = {
  indicator: {
    marginRight: 10
  }
};

const initialFieldCount = 10;

export default function BasicInfo(props) {
  let {
    attribute,
    data,
    errors,
    handleChange,
    handleCustomChange,
    setTotalFields,
    setCompletedFields,
    partOfSummary
  } = props;

  const updateCompletedFieldCount = useCallback(() => {
    let completed = 0;
    if (data.name && !errors.name) completed++;
    if (data.lastName && !errors.lastName) completed++;
    if (data.identification && !errors.identification) completed++;
    if (data.identificationType && !errors.identificationType) completed++;
    if (data.birthday && !errors.birthday) completed++;
    if (data.residenceCity && !errors.residenceCity) completed++;
    if (data.email && !errors.email) completed++;
    if (data.phone && !errors.phone) completed++;
    if (data.maritalStatus && !errors.maritalStatus) completed++;
    if (data.termsAndConditions && !errors.termsAndConditions) completed++;
    setCompletedFields(completed);
  }, [data, errors, setCompletedFields]);

  const updateTotalFieldCount = useCallback(() => {
    let fieldCount = initialFieldCount;
    setTotalFields(fieldCount);
  }, [setTotalFields]);

  useEffect(() => {
    updateTotalFieldCount();
    updateCompletedFieldCount();
  }, [props, updateTotalFieldCount, updateCompletedFieldCount]);

  const changeData = (name, value, error) => {
    if (partOfSummary) {
      handleCustomChange(attribute, name, value, error);
    } else handleChange(name, value, error);
  };

  const handleName = (event) => {
    const error = !validateAlphabeticInput(event.target.value);
    changeData(event.target.name, event.target.value, error);
  };

  const handleBirthday = (date) => {
    const error = !validateBirthday(date);
    if (!date) {
      changeData('birthday', null, true);
      return;
    }
    if (data.expeditionDate && new Date(data.expeditionDate) < date.toDate()) {
      changeData('expeditionDate', data.expeditionDate, true);
    } else changeData('expeditionDate', data.expeditionDate, false);
    changeData('birthday', getStringDate(date.toDate()), error);
  };

  const handleExpeditionDate = (date) => {
    let error = false;
    if (!date) {
      changeData('expeditionDate', null, true);
      return;
    }
    if (data.birthday && new Date(data.birthday) > date.toDate()) error = true;
    changeData('expeditionDate', getStringDate(date.toDate()), error);
  };

  const handleEmail = (event) => {
    const error = !validateEmailInput(event.target.value);
    changeData(event.target.name, event.target.value, error);
  };

  const handleIdentification = (event) => {
    const error = !validateNumericInput(event.target.value);
    changeData(event.target.name, event.target.value, error);
  };

  const handlePhone = (event) => {
    const error = !validatePhoneInput(event.target.value);
    changeData(event.target.name, event.target.value, error);
  };

  const handleResidenceCity = (event, value) => {
    changeData('residenceCity', value, false);
  };

  const handleIDType = (event, value) => {
    changeData('identificationType', value, false);
  };

  const handleSelect = (event) => {
    changeData(event.target.name, event.target.value, false);
  };

  const handleIsReported = (event) => {
    changeData(event.target.name, event.target.value === 'Si', false);
  };

  const getInitalDate = () => {
    let currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 18);
    return currentDate;
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <React.Fragment>
          {!partOfSummary && (
            <MediaQuery maxDeviceWidth={760}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" align="center">
                  Ingresa tus datos personales
                </Typography>
              </Grid>
            </MediaQuery>
          )}
          {!partOfSummary && (
            <Grid item xs={12} sm={12}>
              <Typography variant="subtitle1" align="center" style={{ margin: '0 5%' }}>
                Asegúrate de que tus datos aparezcan de la misma forma que en tu documento de identidad
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              id="name"
              name="name"
              label="Nombres"
              fullWidth
              autoComplete="fname"
              value={data.name || ''}
              error={errors.name}
              helperText={errors.name ? 'Revisa que tu nombre solo tenga letras' : null}
              onChange={handleName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="lastName"
              name="lastName"
              label="Apellidos"
              fullWidth
              autoComplete="lname"
              error={errors.lastName}
              helperText={errors.lastName ? 'Revisa que tu apellido solo tenga letras' : null}
              onChange={handleName}
              value={data.lastName || ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              noOptionsText="No hay opciones"
              options={['Cédula de ciudadanía', 'Cédula de extranjería']}
              defaultValue={() => data.identificationType}
              getOptionLabel={(option) => option}
              onChange={handleIDType}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="identificationType"
                  value={data.identificationType || ''}
                  label="Tipo de tu identificación"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="identification"
              type="number"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              name="identification"
              label="Número de identificación"
              fullWidth
              error={errors.identification}
              helperText={errors.identification ? 'Revisa que sea un número válido' : null}
              onChange={handleIdentification}
              value={data.identification || ''}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
              <DatePicker
                fullWidth
                autoOk
                clearable
                openTo="year"
                name="birthday"
                label="Fecha de nacimiento"
                initialFocusedDate={getInitalDate()}
                value={data.birthday ? new Date(data.birthday) : null}
                onChange={(date) => handleBirthday(date)}
                error={errors.birthday}
                helperText={errors.birthday ? 'Debes tener 18 años para pedir un crédito' : null}
                maxDate={new Date()}
                format="LL"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
              <DatePicker
                fullWidth
                autoOk
                clearable
                openTo="year"
                name="expeditionDate"
                label="Fecha expedición documento"
                initialFocusedDate={getInitalDate()}
                value={data.expeditionDate ? new Date(data.expeditionDate) : null}
                onChange={(date) => handleExpeditionDate(date)}
                error={errors.expeditionDate}
                helperText={
                  errors.expeditionDate ? 'La fecha de expedición debe ser después de la de nacimiento' : null
                }
                maxDate={new Date()}
                format="LL"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="none">
              <InputLabel>Estado civil</InputLabel>
              <Select
                fullWidth
                value={data.maritalStatus || ''}
                name="maritalStatus"
                onChange={handleSelect}
                label="Estado civil"
              >
                <MenuItem value={'Casado'}>Casado</MenuItem>
                <MenuItem value={'Divorciado'}>Divorciado</MenuItem>
                <MenuItem value={'Soltero'}>Soltero</MenuItem>
                <MenuItem value={'Union libre'}>Unión libre</MenuItem>
                <MenuItem value={'Viudo'}>Viudo</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="email"
              name="email"
              type="email"
              label="Correo electrónico"
              fullWidth
              autoComplete="email"
              error={errors.email}
              helperText={errors.email ? 'Ingresa un correo válido' : null}
              onChange={handleEmail}
              value={data.email || ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="phone"
              name="phone"
              type="tel"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              label="Número de celular"
              fullWidth
              autoComplete="tel"
              error={errors.phone}
              helperText={errors.phone ? 'Ingresa un número válido' : null}
              onChange={handlePhone}
              value={data.phone || ''}
              InputProps={{
                startAdornment: <Chip label="+57" color="secondary" style={classes.indicator} />
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              noOptionsText="No hay opciones"
              options={cities}
              getOptionLabel={(option) => option}
              defaultValue={() => data.residenceCity}
              onChange={handleResidenceCity}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={data.residenceCity || ''}
                  label="Ciudad de residencia"
                  fullWidth
                  autoComplete="off"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="none">
              <InputLabel>¿Estás reportado en centrales de riesgo?</InputLabel>
              <Select
                fullWidth
                value={typeof data.isReported === 'boolean' ? (data.isReported ? 'Si' : 'No') : ''}
                name="isReported"
                onChange={handleIsReported}
                label="Reportado en centrales de riesgo"
              >
                <MenuItem value={'Si'}>Si</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {!partOfSummary && (
            <Grid item xs={12} sm={12}>
              <Grid container alignContent="space-between" justify="center" align="center">
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        type="checkbox"
                        color="primary"
                        name="termsAndConditions"
                        checked={data.termsAndConditions || false}
                        onChange={(event) => handleChange(event.target.name, event.target.checked, false)}
                      />
                    }
                  />
                </Grid>
                <Grid item>
                  <TermsAndConditions handleClick={() => handleChange('termsAndConditions', true, false)} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </React.Fragment>
      </Grid>
    </React.Fragment>
  );
}
