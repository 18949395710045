import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useCallback, useEffect } from 'react';
import MediaQuery from 'react-responsive';
import { validateMoneyInput } from '../../../controllers/validators';
import vehicleCondition from '../../../data/vehicleCondition';
import vehicleUseType from '../../../data/vehicleUseType';
import CurrencyFormat from '../../common/CurrencyFormat';
import DeadlineSlider from '../utils/DeadlineSlider';
import AmountSlider from '../utils/AmountSlider';

const initialFieldCount = 5;

const useStyles = makeStyles((theme) => ({
  longTextField: {
    '& label': {
      height: '2em',
    },
  },
}));

export default function VehicleCredit(props) {
  let {
    attribute,
    data,
    errors,
    handleChange,
    handleCustomChange,
    setTotalFields,
    setCompletedFields,
    partOfSummary,
  } = props;

  const classesStyles = useStyles();

  const updateCompletedFieldCount = useCallback(() => {
    let completed = 0;
    if (data.vehicleValue && !errors.vehicleValue) completed++;
    if (data.amount && !errors.amount) completed++;
    if (data.creditDeadline >=0 && !errors.creditDeadline) completed++;
    if (data.vehicleCondition && !errors.vehicleCondition) completed++;
    if (data.useType && !errors.useType) completed++;
    if (data.useTypeDetail && !errors.useTypeDetail) completed++;
    if (data.vehicleConditionDetail && !errors.vehicleConditionDetail) completed++;
    setCompletedFields(completed);
  }, [data, errors, setCompletedFields]);

  const updateTotalFieldCount = useCallback(() => {
    let fieldCount = initialFieldCount;
    if (data.useType && data.useType.detail) {
      fieldCount += 1;
    }
    if (data.vehicleCondition && data.vehicleCondition.detail) {
      fieldCount += 1;
    }
    setTotalFields(fieldCount);
  }, [setTotalFields, data]);

  useEffect(() => {
    updateTotalFieldCount();
    updateCompletedFieldCount();
  }, [props, updateTotalFieldCount, updateCompletedFieldCount]);

  const changeData = (name, value, error) => {
    if (partOfSummary) {
      handleCustomChange(attribute, name, value, error);
    } else handleChange(name, value, error);
  };

  const handleAmount = (value) => {
    changeData('amount', parseInt(value), false);
  };

  const handleVehicleValue = (event) => {
    handleAmount(event.target.value/2);
    const error = !validateMoneyInput(event.target.value);
    changeData('vehicleValue', parseInt(event.target.value), error);
  };

  const handleUseType = (event, value) => {
    if (value === null) {
      changeData('useTypeDetail', null, false);
    } else if (!value.detail) {
      changeData('useTypeDetail', null, false);
    }
    changeData('useType', value, false);
  };

  const handleCreditDeadline = (value) => {
    changeData('creditDeadline', value, false);
  };

  const handleUseTypeDetail = (event, value) => {
    changeData('useTypeDetail', value, false);
  };

  const handleVehicleCondition = (event, value) => {
    if (value === null) {
      changeData('vehicleConditionDetail', null, false);
    } else if (!value.detail) {
      changeData('vehicleConditionDetail', null, false);
    }
    changeData('vehicleCondition', value, false);
  };

  const handleVehicleConditionDetail = (event, value) => {
    changeData('vehicleConditionDetail', value, false);
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {!partOfSummary && (
          <MediaQuery maxDeviceWidth={760}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" align="center">
                Ingresa la información de tu crédito
              </Typography>
            </Grid>
          </MediaQuery>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            name="vehicleValue"
            label="¿Cuánto vale el vehículo que quieres?"
            fullWidth
            autoComplete="off"
            value={data.vehicleValue || ''}
            error={errors.vehicleValue}
            helperText={errors.vehicleValue ? 'Revisa que sea un número válido' : null}
            onChange={handleVehicleValue}
            InputProps={{
              inputComponent: CurrencyFormat,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            noOptionsText="No hay opciones"
            options={vehicleCondition}
            getOptionLabel={(option) => option.name}
            onChange={handleVehicleCondition}
            defaultValue={() => data.vehicleCondition}
            renderInput={(params) => (
              <div className={classesStyles.longTextField}>
                <TextField
                  {...params}
                  value={data.vehicleCondition || ''}
                  name="vehicleCondition"
                  label="¿En qué estado está el vehículo?"
                  fullWidth
                />
              </div>
            )}
          />
        </Grid>
        {data.vehicleCondition && data.vehicleCondition.detail ? (
          <React.Fragment>
            <Grid item xs={12}>
              <Autocomplete
                noOptionsText="No hay opciones"
                options={data.vehicleCondition.detail}
                getOptionLabel={(option) => option}
                onChange={handleVehicleConditionDetail}
                defaultValue={() => data.vehicleConditionDetail}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={data.vehicleConditionDetail || ''}
                    label="¿Qué modelo es el vehículo?"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </React.Fragment>
        ) : null}
        <Grid item xs={12}>
          <Autocomplete
            noOptionsText="No hay opciones"
            options={vehicleUseType}
            getOptionLabel={(option) => option.name}
            onChange={handleUseType}
            defaultValue={() => data.useType}
            renderInput={(params) => (
              <TextField {...params} value={data.useType || ''} name="useType" label="¿Qué tipo de uso es?" fullWidth />
            )}
          />
        </Grid>
        {data.useType && data.useType.detail ? (
          <React.Fragment>
            <Grid item xs={12}>
              <Autocomplete
                noOptionsText="No hay opciones"
                options={data.useType.detail}
                getOptionLabel={(option) => option}
                onChange={handleUseTypeDetail}
                defaultValue={() => data.useTypeDetail}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={data.useTypeDetail || ''}
                    label="¿Para qué actividad lo quieres?"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </React.Fragment>
        ) : null}
        {!partOfSummary && (
          <Grid item xs={12} sm={12}>
            <AmountSlider onChange={handleAmount} value={data.amount} maximum={data.vehicleValue || null} />
          </Grid>
        )}
        {!partOfSummary && (
          <Grid item xs={12} sm={12}>
            <DeadlineSlider onChange={handleCreditDeadline} value={data.creditDeadline} />
          </Grid>
        )}
        
      </Grid>
    </React.Fragment>
  );
}
