const vehicleUseType = [
    { name: 'Particular'},
    { name: 'Público', detail: 
        [
            'Taxi',
            'Camioneta',
            'Van de cargo',
            'Van de pasajeros',
            'Pick up',
            'Camión',
            'Volqueta',
            'Bus',
            'Microbus',
            'Buseton'
        ]
    },
]

export default vehicleUseType;