import React, { useCallback, useEffect } from 'react';

import CurrencyFormat from '../../common/CurrencyFormat';
import Grid from '@material-ui/core/Grid';
import MediaQuery from 'react-responsive';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { validateMoneyInput } from '../../../controllers/validators';

const initialFieldCount = 2;

export default function FinancialInformation(props) {
  let {
    attribute,
    data,
    errors,
    handleChange,
    handleCustomChange,
    setTotalFields,
    setCompletedFields,
    partOfSummary,
  } = props;

  const updateCompletedFieldCount = useCallback(() => {
    let completed = 0;
    if (data.income && !errors.income) completed++;
    if (data.assets && !errors.assets) completed++;
    setCompletedFields(completed);
  }, [data, errors, setCompletedFields]);

  const updateTotalFieldCount = useCallback(() => {
    let fieldCount = initialFieldCount;
    setTotalFields(fieldCount);
  }, [setTotalFields]);

  useEffect(() => {
    updateTotalFieldCount();
    updateCompletedFieldCount();
  }, [props, updateTotalFieldCount, updateCompletedFieldCount]);

  const changeData = (name, value, error) => {
    if (partOfSummary) {
      handleCustomChange(attribute, name, value, error);
    } else handleChange(name, value, error);
  };

  const handleChangeValue = (event, name) => {
    const error = !validateMoneyInput(event.target.value);
    changeData(name, parseInt(event.target.value), error);
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {!partOfSummary && (
          <MediaQuery maxDeviceWidth={760}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" align="center">
                Ingresa la información financiera de la empresa
              </Typography>
            </Grid>
          </MediaQuery>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            name="income"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            label="Ingresos del último año"
            fullWidth
            autoComplete="off"
            value={data.income || ''}
            error={errors.income}
            helperText={errors.income ? 'Revisa que sea un número válido' : null}
            onChange={(value) => handleChangeValue(value, 'income')}
            InputProps={{
              inputComponent: CurrencyFormat,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="assets"
            label="Activos totales"
            fullWidth
            autoComplete="off"
            value={data.assets || ''}
            error={errors.assets}
            helperText={errors.assets ? 'Revisa que sea un número válido' : null}
            onChange={(value) => handleChangeValue(value, 'assets')}
            InputProps={{
              inputComponent: CurrencyFormat,
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
