import React, { useCallback, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import MediaQuery from 'react-responsive';
import Typography from '@material-ui/core/Typography';
import DeadlineSlider from '../utils/DeadlineSlider';
import AmountSlider from '../utils/AmountSlider';

const initialFieldCount = 2;

export default function InvestmentCredit(props) {
  let {
    attribute,
    data,
    errors,
    handleChange,
    handleCustomChange,
    setTotalFields,
    setCompletedFields,
    partOfSummary,
  } = props;

  const updateCompletedFieldCount = useCallback(() => {
    let completed = 0;
    if (data.amount && !errors.amount) completed++;
    if (data.creditDeadline >=0 && !errors.creditDeadline) completed++;
    setCompletedFields(completed);
  }, [data, errors, setCompletedFields]);

  const updateTotalFieldCount = useCallback(() => {
    let fieldCount = initialFieldCount;

    setTotalFields(fieldCount);
  }, [setTotalFields]);

  useEffect(() => {
    updateTotalFieldCount();
    updateCompletedFieldCount();
  }, [props, updateTotalFieldCount, updateCompletedFieldCount]);

  const changeData = (name, value, error) => {
    if (partOfSummary) {
      handleCustomChange(attribute, name, value, error);
    } else handleChange(name, value, error);
  };

  const handleAmount = (value) => {
    changeData('amount', value, false);
  };

  const handleCreditDeadline = (value) => {
    changeData('creditDeadline', value, false);
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {!partOfSummary && (
          <MediaQuery maxDeviceWidth={760}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" align="center">
                Ingresa la información de tu crédito
              </Typography>
            </Grid>
          </MediaQuery>
        )}

        {!partOfSummary && (
          <Grid item xs={12} sm={12}>
            <AmountSlider onChange={handleAmount} value={data.amount} minimum={100000} maximum={10000000}/>
          </Grid>
        )}
        {!partOfSummary && (
          <Grid item xs={12} sm={12}>
            <DeadlineSlider onChange={handleCreditDeadline} value={data.creditDeadline} minimum={0.5} maximum={36} />
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}
