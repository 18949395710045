import Colors from '../../constants/Colors';
import { Grid } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  creditTypePaper: {
    backgroundColor: Colors.primary,
    borderRadius: '50px',
    borderColor: Colors.white,
    borderStyle: 'solid',
    borderWidth: 1,
    height: 'auto',
    padding: '2% 5%',
    backgroundSize: '300% 100%',
    'moz-transition': 'all .4s ease-in-out',
    '-o-transition': 'all .4s ease-in-out',
    '-webkit-transition': 'all .4s ease-in-out',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundPosition: '100% 0',
      'moz-transition': 'all .4s ease-in-out',
      '-o-transition': 'all .4s ease-in-out',
      '-webkit-transition': 'all .4s ease-in-out',
      transition: 'all .4s ease-in-out'
    },
    backgroundImage: `linear-gradient(to right, ${Colors.primary} 0%, #16085B 85%, ${Colors.primary} 100%)`
  },
  creditTypePaperImg: {
    margin: '5%',
    marginBottom: '0%'
  },
  creditTypePaperText: {
    color: 'white',
    textAlign: 'center',
    padding: '5px 0',
    fontSize: '20px',
    fontWeight: '500'
  },
  link: {
    textDecoration: 'none'
  }
}));

const CreditType = ({ name, url }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <NavLink to={`/credito/${url}`} className={classes.link}>
        <Paper className={classes.creditTypePaper}>
          <Grid container spacing={1} alignItems="center" justify="center">
            <Grid item xs={3}>
              <img
                src={
                  window.location.hostname === 'colsubsidio.financialomejor.com'
                    ? './images/' + url + '-amarillo.png'
                    : './images/' + url + '.png'
                }
                alt={name}
                height="80%"
                width="90%"
                className={classes.creditTypePaperImg}
              />
            </Grid>
            <Grid item xs={9}>
              <Typography className={classes.creditTypePaperText}>CRÉDITO DE {name.toUpperCase()}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </NavLink>
    </React.Fragment>
  );
};

export default CreditType;
