import { Container, Grid } from '@material-ui/core';

import AmountSlider from '../utils/AmountSlider';
import DeadlineSlider from '../utils/DeadlineSlider';
import React from 'react';

const CreditResults = ({ data, type, step, handleChange, parameters }) => {
  const handleCreditDeadline = (value) => {
    handleChange(step, 'creditDeadline', value, false);
  };

  const handleAmount = (value) => {
    handleChange(step, 'amount', value, false);
  };

  return step ? (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <AmountSlider
            onChange={handleAmount}
            value={data.amount}
            currency={data.currency ? data.currency : null}
            maximum={
              data.vehicleValue
                ? data.vehicleValue
                : type === 'quicklink'
                ? Math.min(50000000, parameters.maxLendingValueInResults)
                : type === 'quicklink'
                ? Math.min(100000000, parameters.maxLendingValueInResults)
                : Math.min(500000000, parameters.maxLendingValueInResults)
            }
            minimum={parameters.minLendingValueInResults || null}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DeadlineSlider
            onChange={handleCreditDeadline}
            value={data.creditDeadline}
            maximum={parameters.maxLendingPeriodInResults}
            minimum={parameters.minLendingPeriodInResults || null}
          />
        </Grid>
      </Grid>
    </Container>
  ) : null;
};

export default CreditResults;
