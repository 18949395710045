export const getApi = async (route, responseType) => {
  try {
    let result = await fetch(`${process.env.REACT_APP_SERVER_HOST}/${route}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*'
      }
    });
    if (result.status !== 200) throw new Error('Ha ocurrido un error. Intenta de nuevo más tarde.');
    result = responseType === 'BLOB' ? await result.blob() : await result.json();
    return result;
  } catch (err) {
    throw new Error('Ha ocurrido un error. Intenta de nuevo más tarde.');
  }
};

export const postApi = async (route, body, responseType) => {
  try {
    let result = await authorizedFetch(route, body, 'POST');
    if (result.status !== 200) throw new Error('Ha ocurrido un error. Intenta de nuevo más tarde.');
    result = responseType === 'BLOB' ? await result.blob() : await result.json();
    return result;
  } catch (err) {
    throw new Error('Ha ocurrido un error. Intenta de nuevo más tarde.');
  }
};

export const putApi = async (route, body) => {
  try {
    let result = await authorizedFetch(route, body, 'PUT');
    if (result.status !== 200) throw new Error('Ha ocurrido un error. Intenta de nuevo más tarde.');
    result = await result.json();
    return result;
  } catch (err) {
    throw new Error('Ha ocurrido un error. Intenta de nuevo más tarde.');
  }
};

export const deleteApi = async (route) => {
  try {
    let result = await fetch(`${process.env.REACT_APP_SERVER_HOST}/${route}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' }
    });
    if (result.status !== 200) throw new Error('Ha ocurrido un error. Intenta de nuevo más tarde.');
    await result.json();
    return result;
  } catch (err) {
    throw new Error('Ha ocurrido un error. Intenta de nuevo más tarde.');
  }
};

const authorizedFetch = async (route, body, method) => {
  return fetch(`${process.env.REACT_APP_SERVER_HOST}/${route}`, {
    method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  });
};
