import { Route } from 'react-router-dom';

import Form from '../funnelPage/Form';
import MainPageWrapper from './MainPageWrapper';
import React from 'react';
import QuickForm from '../funnelPage/QuickForm';

const LandingPage = (props) => {
  return (
    <React.Fragment>
      <Route exact path="/" component={MainPageWrapper} />
      <Route path="/credito/:type" render={({ match }) => <Form props={props} type={match.params.type} />} />
      <Route path="/rapido" render={( ) => <QuickForm props={props} type={'rapido'} />} />
    </React.Fragment>
  );
};

export default LandingPage;
