import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useCallback, useEffect } from 'react';
import MediaQuery from 'react-responsive';
import { validateMoneyInput } from '../../../controllers/validators';
import antiquityEconomicActivityTimeRanges from '../../../data/antiquityEconomicActivityTimeRanges';
import economicActivityTypes from '../../../data/economicActivityTypes';
import CurrencyFormat from '../../common/CurrencyFormat';


const initialFieldCount = 4;

const useStyles = makeStyles((theme) => ({
  longTextField: {
    height: '2em',
  },
}));

export default function FinancialInformation(props) {
  let {
    attribute,
    data,
    errors,
    handleChange,
    handleCustomChange,
    setTotalFields,
    setCompletedFields,
    partOfSummary,
  } = props;

  const classesStyles = useStyles();

  const updateCompletedFieldCount = useCallback(() => {
    let completed = 0;
    if (data.income && !errors.income) completed++;
    if (data.outcome && !errors.outcome) completed++;
    if (data.assets && !errors.assets) completed++;
    if (data.economicActivity && !errors.economicActivity) completed++;
    if (data.economicActivityDetail && !errors.economicActivityDetail) completed++;
    if (data.antiquity && !errors.antiquity) completed++;
    setCompletedFields(completed);
  }, [data, errors, setCompletedFields]);

  const updateTotalFieldCount = useCallback(() => {
    let fieldCount = initialFieldCount;
    if (data.economicActivity) {
      fieldCount += 1;
      if (data.economicActivity.detail) {
        fieldCount += 1;
      }
    }
    setTotalFields(fieldCount);
  }, [setTotalFields, data]);

  useEffect(() => {
    updateTotalFieldCount();
    updateCompletedFieldCount();
  }, [props, updateTotalFieldCount, updateCompletedFieldCount]);

  const changeData = (name, value, error) => {
    if (partOfSummary) {
      handleCustomChange(attribute, name, value, error);
    } else handleChange(name, value, error);
  };

  const handleChangeValue = (event, name) => {
    const error = !validateMoneyInput(event.target.value);
    changeData(name, parseInt(event.target.value), error);
  };

  const handleEconomicActivity = (event, value) => {
    handleEconomicActivityDetail(event, null);
    if (!value) handleAntiquity(event, null);
    changeData('economicActivity', value, false);
  };

  const handleEconomicActivityDetail = (event, value) => {
    changeData('economicActivityDetail', value, false);
  };

  const handleAntiquity = (event, value) => {
    changeData('antiquity', value, false);
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {!partOfSummary && (
          <MediaQuery maxDeviceWidth={760}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" align="center">
                Ingresa tu información financiera
              </Typography>
            </Grid>
          </MediaQuery>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            name="income"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            label="Ingresos mensuales"
            fullWidth
            autoComplete="off"
            value={data.income || ''}
            error={errors.income}
            helperText={errors.income ? 'Revisa que sea un número válido' : null}
            onChange={(value) => handleChangeValue(value, 'income')}
            InputProps={{
              inputComponent: CurrencyFormat,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="outcome"
            label="Gastos mensuales"
            fullWidth
            autoComplete="off"
            value={data.outcome || ''}
            error={errors.outcome}
            helperText={errors.outcome ? 'Revisa que sea un número válido' : null}
            onChange={(value) => handleChangeValue(value, 'outcome')}
            InputProps={{
              inputComponent: CurrencyFormat,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="assets"
            label="¿Cuánto suman tus activos?"
            fullWidth
            autoComplete="off"
            value={data.assets || ''}
            error={errors.assets}
            helperText={errors.assets ? 'Revisa que sea un número válido' : null}
            onChange={(value) => handleChangeValue(value, 'assets')}
            InputProps={{
              inputComponent: CurrencyFormat,
              classes: {
                input: classesStyles.longTextField,
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            noOptionsText="No hay opciones"
            options={economicActivityTypes}
            getOptionLabel={(option) => option.name}
            onChange={handleEconomicActivity}
            defaultValue={() => data.economicActivity}
            renderInput={(params) => (
              <TextField
                {...params}
                value={data.economicActivity || ''}
                name="economicActivity"
                label="¿En qué trabajas?"
                fullWidth
              />
            )}
          />
        </Grid>
        {data.economicActivity && data.economicActivity.detail && data.economicActivity.name === 'Asalariado' && (
          <Grid item xs={12}>
            <Autocomplete
              noOptionsText="No hay opciones"
              options={data.economicActivity.detail}
              getOptionLabel={(option) => option}
              onChange={handleEconomicActivityDetail}
              defaultValue={() => data.economicActivityDetail}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="economicActivityDetail"
                  value={data.economicActivityDetail || ''}
                  name="economicActivityDetail"
                  label={'¿Qué tipo de contrato tienes?'}
                  fullWidth
                />
              )}
            />
          </Grid>
        )}
        {data.economicActivity && data.economicActivity.detail && data.economicActivity.name === 'Independiente' && (
          <Grid item xs={12}>
            <Autocomplete
              noOptionsText="No hay opciones"
              options={data.economicActivity.detail}
              getOptionLabel={(option) => option}
              onChange={handleEconomicActivityDetail}
              defaultValue={() => data.economicActivityDetail}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="economicActivityDetail"
                  value={data.economicActivityDetail || ''}
                  name="economicActivityDetail"
                  label={'¿Cuál es tu actividad economica?'}
                  fullWidth
                />
              )}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          {data.economicActivity ? (
            <Autocomplete
              noOptionsText="No hay opciones"
              options={antiquityEconomicActivityTimeRanges}
              getOptionLabel={(option) => option}
              onChange={handleAntiquity}
              defaultValue={() => data.antiquity}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="antiquity"
                  value={data.antiquity || ''}
                  name="antiquity"
                  label={
                    data.economicActivity && data.economicActivity.name === 'Pensionado'
                      ? '¿Hace cuánto eres pensionado?'
                      : '¿Cuánto tiempo llevas trabajando?'
                  }
                  fullWidth
                />
              )}
            />
          ) : null}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
