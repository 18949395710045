import './App.css';

import { BrowserRouter, Route } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';

import Colors from './constants/Colors';
import LandingPage from '../src/components/landing-page/LandingPage';
import PaymentDetail from '../src/components/paymentPage/PaymentDetail';
import PaymentDone from '../src/components/paymentPage/PaymentDone';
import PaymentPage from '../src/components/paymentPage/PaymentPage';
import ReactGA from 'react-ga';
import ScrollToTop from '../src/components/common/ScrollToTop';
import { esES } from '@material-ui/core/locale';

const theme = createMuiTheme(
  {
    overrides: {
      MuiStepIcon: {
        root: {
          '&$completed': {
            color: Colors.primary
          },
          '&$active': {
            color: Colors.secondary
          }
        },
        active: {},
        completed: {}
      },
      MuiStepConnector: {
        active: {
          '& $line': {
            borderColor: '#27097B',
            borderWidth: 'medium'
          }
        },
        completed: {
          '& $line': {
            borderColor: '#27097B',
            borderWidth: 'medium'
          }
        }
      }
    },
    palette: {
      primary: {
        main: Colors.primary
      },
      secondary: {
        main: Colors.secondary
      },
      text: {
        primary: '#4a4a4a'
      }
    },
    status: {
      danger: 'orange'
    },
    typography: {
      fontFamily: ['Montserrat', 'sans-serif'].join(',')
    }
  },
  esES
);

function App() {
  ReactGA.initialize(process.env.REACT_APP_FMV2_GOOGLE_ANALYTICS_TAG);
  ReactGA.set({
    main: 'FM 2.0'
  });
  ReactGA.pageview('/');

  useEffect(() => {
    if (window.location.hostname === 'colsubsidio.financialomejor.com') {
      document.title = 'Colsubsidio';
      const favicon = document.getElementById('favicon');
      favicon.href = 'colsubsidio.png';
    }
  }, []);

  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <ScrollToTop>
            <Route
              exact
              path="/pago-finalizado/:paymentID"
              render={({ match }) => {
                return <PaymentDone paymentID={match.params.paymentID} />;
              }}
            />
            <Route
              exact
              path="/pago/:creditNumber"
              render={({ match }) => {
                return <PaymentDetail creditNumber={match.params.creditNumber} />;
              }}
            />
            <Route exact path="/pago" component={PaymentPage} />
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/credito/libre-inversion" component={LandingPage} />
            <Route exact path="/rapido" component={LandingPage} />
          </ScrollToTop>
        </BrowserRouter>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
