import { Button, Container, Paper, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import {
  getCreditInfoPage,
  getDataToSend,
  monthlyFeeFromAnnualInterest,
  setInitialForm,
  updateFormWithParameters
} from '../logic/form';

import BasicInfo from './basicInfo/BasicInfo';
import BasicInfoCorporate from './basicInfo/BasicInfoCorporate';
import BasicInfoQuicklink from './basicInfo/BasicInfoQuicklink';
import Colors from '../../constants/Colors';
import CorporateCredit from './creditInfo/CorporateCredit';
import FinancialInformation from './financialInfo/FinancialInformation';
import FinancialInformationCorporate from './financialInfo/FinancialInformationCorporate';
import InvestmentCredit from './creditInfo/InvestmentCredit';
import ReactGA from 'react-ga';
import SuccessMessage from './summary/SuccessMessage';
import Summary from './summary/Summary';
import VehicleCredit from './creditInfo/VehicleCredit';
import data from '../../data/directrices.json';
import { deadlines } from '../../data/sliders';
import { makeStyles } from '@material-ui/core/styles';
import { postApi } from '../api/apiManager';
import utilFunctions from '../../controllers/utilFunctions';

export default function QuickForm({ type, ally, props }) {
  const classes = useStyles();
  const [requestID, setRequestID] = useState('');
  const [request, setRequest] = useState({});
  const [credit, setCredit] = useState({});
  const [steps, setSteps] = useState([]);
  const [lendersFetchDone, setLendersFetchDone] = useState(false);
  const [initialParametersUsed, setInitialParametersUsed] = useState(false);
  const [creditResults, setcreditResults] = useState([]);
  const [filteredCreditResults, setfilteredCreditResults] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [totalFields, setTotalFields] = useState(0);
  const [completedFields, setCompletedFields] = useState(0);
  const [selectedLender, setSelectedLender] = useState({});
  const [sliderParameters, setSliderParameters] = useState({});
  const [initalCreditResultsSaved, setInitalCreditResultsSaved] = useState(false);
  const [sentSummary, setSentSummary] = useState(false);
  const [maxStep, setMaxStep] = useState(0);

  const [form, setForm] = useState({
    data: { ...setInitialForm(type) },
    errors: {}
  });

  useEffect(() => {
    if (activeStep > maxStep) setMaxStep(activeStep);
  }, [activeStep, maxStep]);

  useEffect(() => {
    if (activeStep < steps.length) {
      let labelName = '';
      if (type === 'vehiculo') {
        labelName = 'credito de vehículo';
      } else if (type === 'vivienda') {
        labelName = 'credito de vivienda';
      } else if (type === 'libre-inversion') {
        labelName = 'credito de libre inversión';
      } else if (type === 'empresas') {
        labelName = 'credito empresarial';
      } else if (type === 'quicklink') {
        labelName = 'Aliado: ' + ally.name;
      }
      ReactGA.event({
        category: 'funnel-' + type,
        action: 'Paso: ' + (activeStep + 1) + ' ' + type,
        label: labelName
      });
    }
  }, [activeStep, type, steps, ally]);

  useEffect(() => {
    ReactGA.pageview('/' + type);
  }, [type]);

  useEffect(() => {
    const filtered = data.filter((item) => item.url === type);
    if (filtered[0]) {
      setCredit(filtered[0]);
      setSteps(filtered[0].pages);
    } else {
      props.history.push('/credito');
    }
  }, [type, props.history]);

  const handleChange = (name, value, error) => {
    form.data[steps[activeStep].attribute][name] = value;
    form.errors[name] = error;
    setForm({ ...form });
  };

  const handleCustomChange = (step, name, value, error) => {
    form.data[step][name] = value;
    form.errors[name] = error;
    setForm({ ...form });
  };

  const handleSend = () => {
    if (!sentSummary) {
      const attribute = steps[activeStep].attribute;
      if (attribute === 'summary') setSentSummary(true);
      if (!(attribute === 'summary' && selectedLender.redirectType === 'DC')) {
        setActiveStep(activeStep + 1);
      }
      sendData(attribute);
      setTimeout(window.scrollTo(0, 1), 500);
    }
  };

  const handleSendDC = async (data) => {
    setActiveStep(activeStep + 1);
    setTimeout(window.scrollTo(0, 1), 500);
    try {
      data.source =
        window.location.hostname === 'colsubsidio.financialomejor.com'
          ? 'Colsubsidio'
          : process.env.REACT_APP_ALLY_NAME;
      await postApi('funnel/update-form', {
        step: 'DC',
        data,
        type,
        requestID
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateResults = useCallback(() => {
    let results = [];
    let exclusiveResults = [];
    creditResults.forEach((credit) => {
      let validCredit = true;
      let creditPage = getCreditInfoPage(type);

      let amount = form.data[creditPage].amount;
      const term = deadlines[form.data[creditPage].creditDeadline].months;
      if (amount > credit.maxLendingValue || amount < credit.minLendingValue) validCredit = false;
      if (term > credit.maxLendingPeriodMonths || term < credit.minLendingPeriodMonths) validCredit = false;

      let monthlyFeeValue = 0;
      let feeComponents = {};
      if (credit.instalmentCalculation) {
        let { result, components } = utilFunctions[credit.instalmentCalculation](
          amount,
          term,
          credit.interestResult.interest
        );
        monthlyFeeValue = result;
        feeComponents = components;
      } else monthlyFeeValue = monthlyFeeFromAnnualInterest(credit.interestResult.interest, term, amount);

      if (validCredit) {
        let current = {
          ...credit,
          amount,
          term,
          monthlyFee: monthlyFeeValue,
          feeComponents,
          interestResult: credit.interestResult,
          policy: credit.policy,
          conditionalDialog: credit.conditionalDialog
        };
        results.push(current);

        let isExclusive = false;
        if (credit.exclusiveIntervals) {
          credit.exclusiveIntervals.forEach((interval) => {
            if (amount >= interval.min && amount <= interval.max) isExclusive = true;
          });
        }
        if (isExclusive) exclusiveResults.push(current);
      }
    });
    if (exclusiveResults.length > 0) setfilteredCreditResults(exclusiveResults);
    else setfilteredCreditResults(results);
  }, [creditResults, form, type]);

  const fetchLenders = useCallback(async () => {
    if (!lendersFetchDone) {
      try {
        const creditType = type === 'quicklink' || type === 'rapido' ? 'libre-inversion' : type;
        const data = await postApi('funnel/find-lenders', {
          creditType,
          lenderName: process.env.REACT_APP_ALLY_NAME
        });
        setLendersFetchDone(true);
        setcreditResults(data.applicableCredits);
        if (data.applicableCredits.length > 0) setSliderParameters(data.parameters);
      } catch (error) {
        console.log(error);
      }
    }
  }, [lendersFetchDone, type]);

  useEffect(() => {
    if (
      typeof sliderParameters.minLendingPeriodInResults === 'number' &&
      typeof sliderParameters.minLendingValueInResults === 'number' &&
      !initialParametersUsed
    ) {
      const newForm = updateFormWithParameters(type, form, sliderParameters);
      setForm(newForm);
      updateResults();
      setInitialParametersUsed(true);
    }
  }, [sliderParameters, form, type, updateResults, initialParametersUsed]);

  useEffect(() => {
    fetchLenders();
  }, [fetchLenders]);

  useEffect(() => {
    updateResults();
  }, [form, creditResults, updateResults]);

  const sendData = useCallback(
    async (attribute) => {
      let data = getDataToSend(attribute, form, selectedLender, filteredCreditResults);
      if (ally && attribute === 'summary') {
        data.basicInfoQuicklink.ally = ally.name;
      }
      try {
        data.source =
          window.location.hostname === 'colsubsidio.financialomejor.com'
            ? 'Colsubsidio'
            : process.env.REACT_APP_ALLY_NAME;
        const response = await postApi('funnel/update-form', {
          step: attribute,
          data,
          type,
          requestID
        });
        setRequestID(response.id);
        if (response.result) setRequest(response.result);
      } catch (error) {
        console.log(error);
      }
    },
    [filteredCreditResults, form, requestID, selectedLender, type, ally]
  );

  useEffect(() => {
    if (!initalCreditResultsSaved && filteredCreditResults.length > 0) {
      sendData('initialResults');
      setInitalCreditResultsSaved(true);
    }
  }, [initalCreditResultsSaved, filteredCreditResults, sendData]);

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const mapNameToComponent = (name, attribute, partOfSummary) => {
    let sharedProps = {
      attribute: attribute,
      data: form.data[attribute],
      errors: form.errors,
      handleChange: handleChange,
      handleCustomChange: handleCustomChange,
      setTotalFields: setTotalFields,
      setCompletedFields: setCompletedFields,
      partOfSummary: partOfSummary
    };
    switch (attribute) {
      case 'basicInfo':
        return <BasicInfo {...sharedProps} />;
      case 'basicInfoCorporate':
        return <BasicInfoCorporate {...sharedProps} />;
      case 'basicInfoQuicklink':
        return <BasicInfoQuicklink {...sharedProps} />;
      case 'vehicleCredit':
        return <VehicleCredit {...sharedProps} />;
      case 'investmentCredit':
        return <InvestmentCredit {...sharedProps} />;
      case 'corporateCredit':
        return (
          <CorporateCredit {...sharedProps} identificationType={form.data.basicInfoCorporate.identificationType} />
        );
      case 'financialInformation':
        return <FinancialInformation {...sharedProps} askReported={type === 'libre-inversion'} />;
      case 'financialInformationCorporate':
        return <FinancialInformationCorporate {...sharedProps} />;
      case 'summary':
        return (
          <Summary
            data={form}
            form={form}
            type={type}
            steps={steps.slice(0, steps.length - 1)}
            renderComponent={mapNameToComponent}
            handleChange={handleCustomChange}
            filteredCreditResults={filteredCreditResults}
            lendersFetchDone={lendersFetchDone}
            selectedLender={selectedLender}
            setSelectedLender={setSelectedLender}
            handleSend={handleSend}
            parameters={sliderParameters}
            handleCustomChange={handleCustomChange}
            errors={form.errors}
            request={request}
            setRequest={setRequest}
            handleSendDC={handleSendDC}
            setForm={setForm}
          />
        );
      default:
        break;
    }
  };

  function getStepContent(step) {
    if (steps[step] && steps[step].name) return mapNameToComponent(steps[step].name, steps[step].attribute, false);
  }

  return (
    <React.Fragment>
      <div className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center" className={classes.gray}>
            {credit.name && `Crédito ${credit.name}`}
          </Typography>
          {ally ? (
            <Container className={classes.allyImageContainer}>
              <img
                src={/^http.*/.test(ally.image) ? ally.image : `${process.env.PUBLIC_URL}/allies/${ally.image}`}
                alt="ally-logo"
                className={classes.allyImage}
              />
            </Container>
          ) : null}

          <React.Fragment>
            {activeStep === steps.length && steps[0] ? (
              <SuccessMessage
                type={type}
                name={credit.name}
                basicInfo={
                  type === 'empresas'
                    ? form.data.basicInfoCorporate
                    : type === 'quicklink'
                    ? form.data.basicInfoQuicklink
                    : form.data.basicInfo
                }
              />
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && activeStep < steps.length - 1 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Volver
                    </Button>
                  )}
                  {totalFields > 0 && completedFields === totalFields && activeStep < steps.length - 1 ? (
                    <Button variant="contained" color="primary" onClick={handleSend} className={classes.button}>
                      Siguiente
                    </Button>
                  ) : totalFields > 0 && activeStep < steps.length - 1 ? (
                    <Typography className={classes.progress}>
                      {credit.name && `${completedFields} de ${totalFields} campos completos`}
                    </Typography>
                  ) : (
                    activeStep === steps.length - 1 && <Typography className={classes.progress}></Typography>
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </div>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  layout: {
    minHeight: '68vh',
    backgroundColor: '',
    width: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up(900 + theme.spacing(1) * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    padding: theme.spacing(2),
    overflow: 'auto'
  },
  stepper: {
    padding: theme.spacing(3, 0, 5)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  progress: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1)
  },
  mobileStepper: {
    background: 'transparent',
    justifyContent: 'center'
  },
  gray: {
    color: Colors.primary
  },
  allyImageContainer: {
    textAlign: 'center',
    paddingTop: '3%'
  },
  allyImage: {
    alignSelf: 'center',
    maxWidth: '70%'
  }
}));
