import { deadlines } from '../../data/sliders';
import marketRules from '../../controllers/rules';
import moment from 'moment-timezone';

export const getStringDate = (date) => {
  const momentDate = moment.tz(date, 'America/Bogota');
  return momentDate.format();
};

export const setInitialForm = (type) => {
  let initialData = {
    basicInfo: {
      isReported: false
    },
    financialInformation: {}
  };

  if (type === 'vehiculo') {
    initialData.vehicleCredit = {
      creditDeadline: 4,
      amount: 1000000
    };
  } else if (type === 'libre-inversion' || type === 'rapido') {
    initialData.investmentCredit = {
      creditDeadline: 0,
      amount: 100000
    };
  } else if (type === 'vivienda') {
    initialData.housingCredit = {};
  } else if (type === 'empresas') {
    delete initialData.basicInfo;
    delete initialData.financialInformation;
    initialData.basicInfoCorporate = {};
    initialData.corporateCredit = {
      creditDeadline: 2,
      amount: 1000000
    };
  } else if (type === 'quicklink') {
    delete initialData.basicInfo;
    delete initialData.financialInformation;
    initialData.basicInfoQuicklink = {
      isReported: false
    };
    initialData.creditInformation = {
      creditDeadline: 0,
      amount: 0
    };
  }
  return initialData;
};

export const isValidCredit = (credit, form) => {
  let validCredit = true;
  if (window.location.hostname !== 'colsubsidio.financialomejor.com') {
    credit.marketRules.forEach((rule) => {
      let validRule = true;
      let atLeastOneValid = false;
      let allValid = true;
      rule.validations.forEach((validations) => {
        let validValidation = true;
        validations.forEach((validation) => {
          const successfulValidation = marketRules[validation.targetFunction](
            validation.targetVariable,
            validation.parameters,
            form.data
          );
          validValidation = validValidation && successfulValidation;
        });
        atLeastOneValid = atLeastOneValid || validValidation;
        allValid = allValid && validValidation;
      });
      if (rule.relation === 'OR') {
        validRule = atLeastOneValid;
      } else if (rule.relation === 'AND') {
        validRule = allValid;
      }
      if (!validRule) validCredit = false;
    });
  }
  return validCredit;
};

export const getCreditInfoPage = (type) => {
  let creditPage = '';
  if (type === 'vehiculo') creditPage = 'vehicleCredit';
  else if (type === 'vivienda') creditPage = 'housingCredit';
  else if (type === 'libre-inversion' || type === 'rapido') creditPage = 'investmentCredit';
  else if (type === 'empresas') creditPage = 'corporateCredit';
  else if (type === 'quicklink') creditPage = 'creditInformation';
  return creditPage;
};

export const getDataToSend = (attribute, form, selectedLender, filteredCreditResults) => {
  let data = {};

  if (attribute === 'summary') {
    data = { ...form.data };
    for (let page in data) {
      for (let property in data[page]) {
        if (Object.prototype.hasOwnProperty.call(data[page], property)) {
          if (property === 'creditDeadline') data[page][property] = deadlines[data[page][property]].months;
          if (data[page][property] && data[page][property].hasOwnProperty('name')) {
            data[page][property] = data[page][property].name;
          }
        }
      }
    }

    data.selectedLender = selectedLender;
    data.lenderResults = filteredCreditResults;
  } else {
    if (attribute === 'initialResults') {
      data.lenderResults = filteredCreditResults;
    } else {
      data = { ...form.data[attribute] };
      for (let property in data) {
        if (Object.prototype.hasOwnProperty.call(data, property)) {
          if (property === 'creditDeadline') data[property] = deadlines[data[property]].months;
          if (data[property] && data[property].hasOwnProperty('name')) data[property] = data[property].name;
        }
      }
    }
  }

  return data;
};

export const updateFormWithParameters = (type, form, sliderParameters) => {
  const dataKeys = Object.keys(form.data);
  dataKeys.forEach((key) => {
    if (typeof form.data[key].creditDeadline === 'number') {
      let index = deadlines.findIndex((deadline) => deadline.months === sliderParameters.minLendingPeriodInResults);
      index = Math.max(0, index);
      form.data[key].creditDeadline = Math.max(form.data[key].creditDeadline, index);
    }
    if (typeof form.data[key].amount === 'number') {
      form.data[key].amount = Math.max(form.data[key].amount, sliderParameters.minLendingValueInResults);
    }
  });
  return { ...form };
};

export const monthlyFeeFromAnnualInterest = (annualInterest, term, amount) => {
  const interest = annualInterest / 100;
  const monthlyInterest = Math.pow(1 + interest, 1 / 12) - 1;
  let monthlyFee = (monthlyInterest * amount) / (1 - Math.pow(1 + monthlyInterest, -term));
  return monthlyFee;
};

export const formatNumberAsCurrency = (value) => {
  let res = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  return res.substring(0, res.length - 3);
};
