import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import { CreditDetails } from './CreditDetails';
import { deadlines } from '../../../../data/sliders';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';

export const CreditDC = ({
  form,
  request,
  selectedLender,
  handleSendDC,
  basicInfo,
  setChoseLender,
  isInterested,
  setIsInterested,
  setSelectedLender,
  choseLender,
  setRequest,
  data,
  setForm
}) => {
  const classes = useStyles();
  const [selectedAccount, setSelectedAccount] = useState({});
  const [accountNumber, setAccountNumber] = useState(null);
  const [automaticDebit, setAutomaticDebit] = useState(false);

  const credit = request.selectedLender ? request.selectedLender : selectedLender;
  let term = credit.term;
  let fee = credit.monthlyFee;
  let amount = credit.amount;
  let feeComponents = credit.feeComponents;
  let amountChanged = credit.amountChanged || false;
  let interest =
    request.selectedLender && request.selectedLender.policy && !request.selectedLender.policy.failedRules
      ? credit.policy.interestResult.interest
      : selectedLender.interestResult.interest;

  const handleAccept = () => {
    setTimeout(window.scrollTo(0, 1), 500);
    if (!isInterested) {
      setSelectedLender(selectedLender);
      setChoseLender(true);
      setIsInterested(true);
    } else {
      const accountDetails = selectedAccount;
      accountDetails.confirmedNumber = accountNumber;
      const data = {
        account: accountDetails,
        automaticDebit,
        basicInfo
      };
      handleSendDC(data);
    }
  };

  const getData = () => {
    let data = {
      isInterested,
      interest,
      amount,
      credit,
      fee,
      feeComponents,
      deadline: term,
      amountChanged
    };
    return data;
  };

  const getDeadline = (deadlineToFind, parameterToFind, parameterToReturn) => {
    const deadline = deadlines.find((deadline) => deadline[parameterToFind] === deadlineToFind);
    return deadline[parameterToReturn];
  };

  useEffect(() => {
    if (data && request && request.selectedLender) {
      const dataDeadline =
        data.data.investmentCredit.creditDeadline === 0.5 ? 1 : data.data.investmentCredit.creditDeadline;
      const dataAmount = data.data.investmentCredit.amount;
      const termValue =
        request.selectedLender.term === 0.5 ? 1 : getDeadline(request.selectedLender.term, 'months', 'value');
      if (choseLender && (termValue !== dataDeadline || request.selectedLender.amount !== dataAmount)) {
        const termInMonths =
          data.data.investmentCredit.creditDeadline === 0.5 ? 0.5 : getDeadline(dataDeadline, 'value', 'months');
        request.selectedLender.term = termInMonths;
        request.selectedLender.amount = dataAmount;
        setRequest({ ...request });
      }
    }
  }, [data, request]);

  return (
    <React.Fragment>
      {isInterested && !request.selectedLender && (
        <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" width="100%">
          <Typography component="h4" variant="h5" align="center" className={classes.gray}>
            Espera un momento por favor
          </Typography>
          <CircularProgress />
        </Box>
      )}
      {isInterested && request.selectedLender && !request.selectedLender.policy && (
        <Typography component="p" variant="body1" align="center" className={classes.gray}>
          Lo sentimos pero tu solicitud con esta entidad no es viable, vuelve a intentarlo en 6 meses.
        </Typography>
      )}
      {(!isInterested ||
        (request.selectedLender && request.selectedLender.policy && !request.selectedLender.policy.failedRules)) && (
        <CreditDetails
          form={form}
          data={getData()}
          classes={classes}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
          accountNumber={accountNumber}
          setAccountNumber={setAccountNumber}
          automaticDebit={automaticDebit}
          setAutomaticDebit={setAutomaticDebit}
          handleAccept={handleAccept}
          basicInfo={basicInfo}
          setForm={setForm}
        />
      )}
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  gray: {
    color: '#666666'
  }
}));
