const corporateEconomicActivityTypes = [
    {"value":"ACTIVIDADESAER", "description": "Actividades artísticas, entretenimiento y recreativas"},
    {"value":"ACTIVIDADESASC", "description": "Actividades de alojamiento y de servicio de comida"},
    {"value":"ACTIVIDADESHCE", "description": "Actividades de los hogares como empleadores"},
    {"value":"ACTIVIDADESSAA", "description": "Actividades de servicios administrativos y apoyo"},
    {"value":"ACTIVIDADESFYS", "description": "Actividades financieras y de seguros"},
    {"value":"ACTIVIDADESTNM", "description": "Actividades inmobiliarias"},
    {"value":"ACTIVIDADESPCT", "description": "Actividades profesionales, científicas y técnicas"},
    {"value":"ADMINISTRACIÓN", "description": "Administración pública -defensa; seguridad social "},
    {"value":"AGRICULTURA", "description": "Agricultura, ganadería, silvicultura y pesca"},
    {"value":"ATENCION", "description": "Atención salud humana y asistencia social"},
    {"value":"COMERCIO", "description": "Comercio al por mayor-menor; reparación vehículos"},
    {"value":"CONSTRUCCION", "description": "Construcción"},
    {"value":"ENSENANZA", "description": "Enseñanza"},
    {"value":"EXPLOTACION", "description": "Explotación de minas y canteras"},
    {"value":"INDUSTRIAS", "description": "Industrias manufactureras"},
    {"value":"INFORMACION", "description": "Información y comunicaciones"},
    {"value":"ORGANIZACIONES", "description": "Organizaciones y órganos extraterritoriales"},
    {"value":"OTRAS", "description": "Otras actividades de servicios"},
    {"value":"SUMINISTROAARDD", "description": "Suministro de agua; aguas residuales, desechos y descontaminación"},
    {"value":"SUMINISTROEGVAA", "description": "Suministro de electricidad, gas, vapor, aire acondicionado"},
    {"value":"TRANSPORTE", "description": "Transporte y almacenamiento"},
]

export default corporateEconomicActivityTypes;