import { Backdrop, Button, CircularProgress, Container, Divider, Grid, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Colors from '../../constants/Colors';
import CssBaseline from '@material-ui/core/CssBaseline';
import Footer from '../common/Footer';
import Header from '../common/Header';
import NumberFormat from 'react-number-format';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { postApi } from '../api/apiManager';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    marginTop: '-1%',
    padding: '15% 15% 5%'
  },
  mainTitle: {
    color: Colors.primary,
    textAlign: 'center',
    fontSize: '2.3em',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.8em',
      textAlign: 'center'
    }
  },
  error: {
    color: Colors.secondary,
    textAlign: 'left',
    fontSize: '1.5em',
    fontWeight: 200,
    marginTop: '2%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.6em'
    }
  },
  logo: {
    marginTop: '1%'
  },
  backdrop: {
    color: Colors.primary
  },
  number: {
    width: '30%',
    minWidth: '300px'
  },
  numberGrid: {
    textAlign: 'center'
  },
  selectBank: {
    width: '30%',
    minWidth: '300px',
    margin: 'auto'
  },
  button: {
    backgroundColor: Colors.secondary,
    color: 'white'
  },
  creditList: {
    marginTop: '3%'
  },
  buttonGrid: {
    textAlign: 'center'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  footer: {
    marginTop: 'auto'
  }
}));

const PaymentSearch = ({ setCreditNumber }) => {
  const classes = useStyles();
  const [number, setNumber] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedParameter, setSelectedParameter] = useState('Número de crédito');
  const [creditList, setCreditList] = useState([]);
  const [fetchDone, setFetchDone] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const cancelPayment = () => {
    setRedirect(true);
  };

  const renderRedirect = () => {
    if (redirect) return <Redirect to='/' />;
  };

  const setNumberValue = (event) => {
    const value = event.target.value;
    setNumber(value);
    if (!value) setError(true);
    else setError(false);
  };

  const renderDate = (date) => {
    if (date) {
      const dateToFormate = new Date(date);
      const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(dateToFormate);
      const month = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(dateToFormate);
      const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(dateToFormate);
      return `${day}-${month}-${year}`;
    }
    return '-';
  };

  const searchCredits = async () => {
    if (!number) setError(true);
    else {
      try {
        setLoading(true);
        const data = await postApi('funnel/find-credit', {
          number: number,
          entity: 'Bancatek',
          numberType: selectedParameter
        });
        setCreditList(data);
        setFetchDone(true);
      } catch (err) {
        console.log('Error', err);
      } finally {
        setLoading(false);
      }
    }
  };

  const selectParameter = (event, value) => {
    setSelectedParameter(value);
  };

  return (
    <div className={classes.root}>
      {renderRedirect()}
      <CssBaseline />
      <div component='main' className={classes.main}>
        <Header />
        <Container className={classes.container} maxWidth={false}>
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color='inherit' />
          </Backdrop>
          <CssBaseline />
          {!loading && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography className={classes.mainTitle}>Ingresa tus datos para realizar el pago</Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  noOptionsText='No hay opciones'
                  className={classes.selectBank}
                  options={['Número de crédito', 'Número de identificación']}
                  value={selectedParameter || ''}
                  getOptionLabel={(option) => option}
                  onChange={selectParameter}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id='identificationType'
                      value={selectedParameter || ''}
                      label='Selecciona el tipo de parametro'
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} className={classes.numberGrid}>
                <TextField
                  name='value'
                  type='number'
                  className={classes.number}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  label={
                    selectedParameter === 'Número de crédito'
                      ? 'Ingresa el número de crédito'
                      : 'Ingresa el número de identificación'
                  }
                  autoComplete='off'
                  value={number || ''}
                  error={error}
                  helperText={error ? 'Revisa que sea un número válido' : null}
                  onChange={(value) => setNumberValue(value)}
                />
              </Grid>
              <Grid item xs={12} className={classes.buttonGrid}>
                <Button onClick={searchCredits} className={classes.button} variant='contained'>
                  Consultar
                </Button>
              </Grid>
              <Grid item xs={12} className={classes.buttonGrid}>
                <Button onClick={() => cancelPayment()} className={classes.button} variant='contained'>
                  Salir
                </Button>
              </Grid>
            </Grid>
          )}
          {!loading && creditList.length > 0 && (
            <div className={classes.creditList}>
              {creditList.map((credit, index) => {
                return (
                  <div className={classes.creditDetail} key={index}>
                    <Divider />
                    <Typography>
                      <strong>Número de crédito:</strong> {credit.selectedLender.creditNumber}
                    </Typography>
                    <Typography>
                      <strong>Fecha oportuna de pago:</strong> {renderDate(credit.issueDate)}
                    </Typography>
                    <Typography>
                      <strong>Valor de cuota miníma: </strong>
                      <NumberFormat
                        value={Math.ceil(credit.selectedLender && credit.selectedLender.monthlyFee)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={0}
                      />
                    </Typography>
                    <Button
                      onClick={() => {
                        setCreditNumber(credit.selectedLender.creditNumber);
                      }}
                      className={classes.button}
                      variant='contained'>
                      Pagar
                    </Button>
                  </div>
                );
              })}
            </div>
          )}
          {!loading && creditList.length === 0 && fetchDone && (
            <Typography className={classes.mainTitle} style={{ marginTop: '1%' }}>
              No se encontraron créditos asociados a la información ingresada, por favor verifica los datos.
            </Typography>
          )}
        </Container>
      </div>
      <footer className={classes.footer}>
        <Footer />
      </footer>
    </div>
  );
};

export default PaymentSearch;
