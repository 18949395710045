import CreditsButtons from './CreditsButtons';
import React from 'react';

const MainPageWrapper = () => {
  return (
    <React.Fragment>
      <CreditsButtons />
    </React.Fragment>
  );
};

export default MainPageWrapper;
