import Button from '@material-ui/core/Button';
import React from 'react';
import TermsAndConditionsDialog from '../common/TermsAndConditionsDialog';

export default function TermsAndConditions(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    props.handleClick(true);
    setOpen(false);
  };

  return (
    <div style={{ display: "inline-block"}}>
      <div style={{fontSize: "0.85rem", marginTop:"5px"}}>ACEPTO
      <Button color="primary" onClick={handleClickOpen}>
        términos y condiciones
      </Button></div>
      <TermsAndConditionsDialog open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} />
    </div>
  );
}
