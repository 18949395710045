export const validateAlphabeticInput = (input) => {
  return /^([A-Za-zñÑáÁéÉíÍóÓúÚ]+[ ]?)+$/.test(input);
};

export const validateNumericInput = (input) => {
  return /^[0-9]+$/.test(input);
};

export const validateEmailInput = (input) => {
  return /^([A-Za-z0-9\-_]+\.?)+@[A-Za-z0-9]+\.([A-Za-z]+\.?)+$/.test(input);
};

export const validatePhoneInput = (input) => {
  const intInput = parseInt(input);
  return /^[0-9]+$/.test(input) && intInput >= 3000000000 && intInput < 3519999999;
};

export const validateBirthday = (input) => {
  const valueEntered = new Date(input).getTime();
  const currentDate = new Date().getTime();
  return currentDate - valueEntered >= 568025140000;
};

export const validateMoneyInput = (input) => {
  return  /^[1-9]+[0-9]+$/.test(input)
}