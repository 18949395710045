const vehicleCondition = [
    { name: 'Nuevo' },
    {
        name: 'Usado', detail: [
            '2020',
            '2019',
            '2018',
            '2017',
            '2016',
            '2015',
            '2014',
            '2013',
            '2012',
            '2011',
            '2010',
            '2009 o anterior'
        ],
    }
]

export default vehicleCondition;