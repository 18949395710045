import { Button, Container, CssBaseline, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { getApi, postApi } from '../api/apiManager';

import Colors from '../../constants/Colors';
import Footer from '../common/Footer';
import Header from '../common/Header';
import NumberFormat from 'react-number-format';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    marginTop: '-1%',
    padding: '15% 15% 5%',
    [theme.breakpoints.down('sm')]: {
      padding: '5% 10%'
    }
  },
  mainTitle: {
    color: Colors.primary,
    textAlign: 'left',
    fontSize: '2.3em',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.8em',
      textAlign: 'center'
    }
  },
  secondaryTitle: {
    color: Colors.primary,
    textAlign: 'left',
    fontSize: '1.5em',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.8em',
      textAlign: 'center'
    }
  },
  backdrop: {
    color: Colors.primary
  },
  footer: {
    marginTop: 'auto'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  button: {
    backgroundColor: Colors.secondary,
    color: 'white'
  },
  buttonGrid: {
    textAlign: 'center'
  }
}));

const PaymentPage = ({ paymentID }) => {
  const classes = useStyles();
  const [paymentState, setPaymentState] = useState(null);
  const [error, setError] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [voucherInfo, setVoucherInfo] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [retry, setRetry] = useState(false);

  const finalizeTransaction = useCallback(async () => {
    if (paymentState) {
      try {
        const data = await postApi('ach/finalize-transaction', {
          paymentID: paymentID,
          paymentState: paymentState
        });
        console.log(data);
      } catch (err) {
        console.log('Error', err);
      }

      switch (paymentState.toUpperCase()) {
        case 'OK':
        case 'SUCCESS':
          //TODO updateExtracts()
        default:
          break;
      }
    }
  }, [paymentID, paymentState]);

  const checkState = useCallback(async () => {
    try {
      const data = await getApi(`ach/transaction-information/${paymentID}`);
      console.log(data, paymentID);
      if (data.wrongPaymentID) setNotFound(true);
      else if (data.transaction && data.transaction.error) setError(true);
      else {
        if (data.transaction && data.transaction.transactionState.value === 'PENDING') setTimeout(checkState, 180000);
        else {
          setPaymentState(
            data.transaction && data.transaction.transactionState.value
              ? data.transaction.transactionState.value
              : data.bill.state
          );
          if (!data.alreadyFinished) finalizeTransaction();
        }
        console.log(data.bill);
        const voucher = {
          cus: data.bill.trazabilityCode,
          ticketID: data.bill.number,
          date: data.bill.issueDate,
          value: data.bill.value,
          description: data.bill.description,
          bank: data.bill.bank.name,
          creditNumber: data.bill.creditNumber
        };
        setVoucherInfo(voucher);
      }
    } catch (error) {
      console.log(error);
    }
  }, [paymentID, finalizeTransaction]);

  const printVoucher = async () => {
    try {
      const blob = await getApi(`ach/print-voucher/${paymentID}`, 'BLOB');
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = 'voucher.pdf';
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkState();
  }, [checkState]);

  const cancelPayment = () => {
    setRedirect(true);
  };

  const retryPayment = () => {
    setRetry(true);
  };

  const renderRedirect = () => {
    if (redirect) return <Redirect to="/" />;
    if (retry) return <Redirect to={`/pago/${voucherInfo.creditNumber}`} />;
  };

  return (
    <div className={classes.root}>
      {renderRedirect()}
      <CssBaseline />
      <div component="main" className={classes.main}>
        <Header />
        <Container className={classes.container} maxWidth={false}>
          {error ? (
            <Typography className={classes.mainTitle}>
              Hubo un problema. Revisa que hayas realizado el pago de manera correcta.
            </Typography>
          ) : paymentState === null && !notFound ? (
            <div>
              <Typography className={classes.mainTitle}>Estado de la transacción pendiente...</Typography>
              <Typography className={classes.secondaryTitle}>
                Por favor verifica si el débito fue realizado en el banco.
              </Typography>
              {voucherInfo !== null && (
                <Typography className={classes.secondaryTitle}>
                  En este momento tu proceso No. {voucherInfo.ticketID} presenta un proceso de pago cuya transacción se
                  encuentra PENDIENTE de recibir confirmación por parte de su entidad financiera. Por favor espera unos
                  minutos y vuelve a consultar más tarde para verificar si tu pago fue confirmado de forma exitosa. Si
                  deseas mayor información sobre el estado actual de tu operación puedes comunicarte a comunícate con
                  Bancatek al correo info@bancatek.com. y preguntar por el estado de la transacción con CUS No{' '}
                  {voucherInfo.cus}
                </Typography>
              )}
              <Typography className={classes.secondaryTitle}>
                En caso de tener inconvenientes con el pago por favor comunícate con Bancatek al correo
                info@bancatek.com.
              </Typography>
            </div>
          ) : (
            <div>
              {!notFound && paymentState.toLowerCase() === 'FAILED'.toLowerCase() && (
                <Typography className={classes.mainTitle}>
                  La transacción fue fallida. Por favor intenta de nuevo.{' '}
                </Typography>
              )}
              {!notFound && paymentState.toLowerCase() === 'NOT_AUTHORIZED'.toLowerCase() && (
                <Typography className={classes.mainTitle}>
                  La transacción fue rechazada. Por favor intenta de nuevo.
                </Typography>
              )}
              {!notFound &&
              (paymentState.toLowerCase() === 'SUCCESS'.toLowerCase() ||
                paymentState.toLowerCase() === 'OK'.toLowerCase()) ? (
                <Typography className={classes.mainTitle}>¡Transacción aprobada!</Typography>
              ) : (
                !notFound && (
                  <div className={classes.buttonGrid}>
                    <Button onClick={() => retryPayment()} className={classes.button} variant="contained">
                      Reintentar pago
                    </Button>
                  </div>
                )
              )}
              {notFound && <Typography className={classes.mainTitle}>Pago finalizado.</Typography>}
            </div>
          )}
          {!notFound && voucherInfo !== null && (
            <div>
              <Typography className={classes.mainTitle}>Comprobante de pago</Typography>
              <ul>
                <li>
                  <Typography className={classes.secondaryTitle}>
                    <strong>NIT: </strong>901.381.592-0
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.secondaryTitle}>
                    <strong>Razón social: </strong>Bancatek S.A.S
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.secondaryTitle}>
                    <strong>Valor de pago: </strong>
                    <NumberFormat
                      value={voucherInfo.value}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                      decimalScale={0}
                    />
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.secondaryTitle}>
                    <strong>Fecha de creación: </strong>
                    {voucherInfo.date}
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.secondaryTitle}>
                    <strong>Entidad bancaria: </strong>
                    {voucherInfo.bank}
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.secondaryTitle}>
                    <strong>CUS: </strong> {voucherInfo.cus}
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.secondaryTitle}>
                    <strong>Número de factura: </strong> {voucherInfo.ticketID}
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.secondaryTitle}>
                    <strong>Descripción del pago: </strong> {voucherInfo.description}
                  </Typography>
                </li>
              </ul>
              <Button onClick={() => printVoucher()} className={classes.button} variant="contained">
                Imprimir comprobante de pago
              </Button>
            </div>
          )}
        </Container>
      </div>
      <div className={classes.buttonGrid}>
        <Button onClick={() => cancelPayment()} className={classes.button} variant="contained">
          Salir
        </Button>
      </div>
      <footer className={classes.footer}>
        <Footer />
      </footer>
    </div>
  );
};

export default PaymentPage;
