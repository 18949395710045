import {
  Box,
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { CreditDC } from './lender-results-utils/CreditDC';
import CreditResults from './CreditResults';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { deadlines } from '../../../data/sliders';
import { makeStyles } from '@material-ui/core/styles';

const Summary = ({
  data,
  form,
  type,
  steps,
  renderComponent,
  handleChange,
  handleSend,
  filteredCreditResults,
  lendersFetchDone,
  selectedLender,
  setSelectedLender,
  parameters,
  handleCustomChange,
  request,
  setRequest,
  handleSendDC,
  setForm
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [parameterStep, setParameterStep] = useState(null);
  const [choseLender, setChoseLender] = React.useState(false);
  const [isInterested, setIsInterested] = useState(false);
  const [requestChanged, setRequestChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePanelChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const dataKeys = Object.keys(data.data);
    dataKeys.forEach((key) => {
      if (typeof data.data[key].creditDeadline === 'number' && typeof data.data[key].amount === 'number') {
        setParameterStep(key);
      }
    });
  });

  useEffect(() => {
    if (choseLender && selectedLender.name && selectedLender.redirectType !== 'DC') {
      handleSend();
    }
  }, [choseLender, selectedLender, handleSend]);

  const getDeadline = (deadlineToFind, parameterToFind, parameterToReturn) => {
    const deadline = deadlines.find((deadline) => deadline[parameterToFind] === deadlineToFind);
    return deadline[parameterToReturn];
  };

  useEffect(() => {
    if (request.selectedLender && !requestChanged) {
      handleChange(parameterStep, 'amount', request.selectedLender.amount, false);
      handleChange(
        parameterStep,
        'creditDeadline',
        request.selectedLender.term === 0.5 ? 1 : getDeadline(request.selectedLender.term, 'months', 'value'),
        false
      );
      setRequestChanged(true);
    }
  }, [request, parameterStep, requestChanged]);

  useEffect(() => {
    if (choseLender && isInterested) {
      handleSend();
      if (!requestChanged) setLoading(true);
      else setLoading(false);
    }
  }, [choseLender, isInterested, handleSend]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {!isInterested && (
          <React.Fragment>
            <Grid item xs={12}>
              {steps.map((step, id) => {
                if (step.attribute === 'investmentCredit') return null;
                else
                  return (
                    <FormExpansionPanel
                      id={id}
                      key={id}
                      handlePanelChange={handlePanelChange}
                      expanded={expanded}
                      renderComponent={renderComponent}
                      title={step.name}
                      attribute={step.attribute}
                    />
                  );
              })}
            </Grid>
            <Grid item xs={12}>
              <CreditResults
                data={data.data[parameterStep]}
                type={type}
                step={parameterStep}
                handleChange={handleChange}
                parameters={parameters}
                handleCustomChange={handleCustomChange}
              />
            </Grid>
            <Grid item xs={12}>
              {!filteredCreditResults[0] && lendersFetchDone && (
                <Typography className={classes.text}>
                  Lo sentimos, en este momento no tenemos créditos que se ajusten a tus necesidades.
                </Typography>
              )}
            </Grid>
          </React.Fragment>
        )}
        {!choseLender && filteredCreditResults[0] && (
          <Grid item xs={12}>
            <CreditDC
              basicInfo={data.data.basicInfo}
              form={form}
              isInterested={isInterested}
              setIsInterested={setIsInterested}
              request={request}
              selectedLender={filteredCreditResults[0]}
              handleSendDC={handleSendDC}
              parameterStep={parameterStep}
              setChoseLender={setChoseLender}
              setSelectedLender={setSelectedLender}
              setForm={setForm}
            />
          </Grid>
        )}
        {choseLender && requestChanged && selectedLender.redirectType === 'DC' && (
          <React.Fragment>
            {request.selectedLender &&
              request.selectedLender.amountChanged &&
              !request.selectedLender.amountChangedReason && (
                <Grid item xs={12}>
                  <CreditResults
                    data={data.data[parameterStep]}
                    type={type}
                    step={parameterStep}
                    handleChange={handleChange}
                    parameters={parameters}
                    handleCustomChange={handleCustomChange}
                  />
                </Grid>
              )}
            <Grid item xs={12}>
              <CreditDC
                form={form}
                isInterested={isInterested}
                setIsInterested={setIsInterested}
                request={request}
                selectedLender={filteredCreditResults[0]}
                handleSendDC={handleSendDC}
                parameterStep={parameterStep}
                setSelectedLender={setSelectedLender}
                choseLender={choseLender}
                setRequest={setRequest}
                data={data}
                setForm={setForm}
              />
            </Grid>
          </React.Fragment>
        )}
        {(!lendersFetchDone || loading) && (
          <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" width="100%">
            <CircularProgress />
          </Box>
        )}
      </Grid>
    </div>
  );
};

const FormExpansionPanel = ({ id, handlePanelChange, expanded, title, attribute, renderComponent }) => {
  const classes = useStyles();
  return (
    <ExpansionPanel
      className={classes.panel}
      expanded={expanded === `panel${id}`}
      onChange={handlePanelChange(`panel${id}`)}
    >
      <ExpansionPanelSummary
        style={{ width: '100%' }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${id}bh-content`}
        id={`panel${id}bh-header`}
      >
        <Typography className={classes.heading}>{title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{renderComponent(title, attribute, true)}</ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Summary;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  panel: {
    padding: '3%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  summaryField: {
    margin: theme.spacing(1)
  },
  text: {
    textAlign: 'center'
  },
  disclaimer: {
    fontSize: '11px',
    color: '#666666'
  },
  formControl: {
    minWidth: 120
  }
}));
