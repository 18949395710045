const cities = [
    "Bogotá D.C., Bogotá D.C.",
    "Medellín, Antioquia",
    "Cali, Valle del Cauca",
    "Barranquilla, Atlántico",
    "Cartagena, Bolívar",
    "Bucaramanga, Santander",
    "Cúcuta, Norte de Santander",
    "Santa Marta, Magdalena",
    "Ibagué, Tolima",
    "Pereira, Risaralda",
    "Manizales, Caldas",
    "Villavicencio, Meta",
    "Valledupar, Cesar",
    "El Encanto, Amazonas",
    "La Chorrera, Amazonas",
    "La Pedrera, Amazonas",
    "La Victoria, Amazonas",
    "Leticia, Amazonas",
    "Miriti Paraná, Amazonas",
    "Puerto Alegría, Amazonas",
    "Puerto Arica, Amazonas",
    "Puerto Nariño, Amazonas",
    "Puerto Santander, Amazonas",
    "Tarapacá, Amazonas",
    "Abejorral, Antioquia",
    "Abriaquí, Antioquia",
    "Alejandría, Antioquia",
    "Amagá, Antioquia",
    "Amalfi, Antioquia",
    "Andes, Antioquia",
    "Angelópolis, Antioquia",
    "Angostura, Antioquia",
    "Anorí, Antioquia",
    "Anza, Antioquia",
    "Apartadó, Antioquia",
    "Arboletes, Antioquia",
    "Argelia, Antioquia",
    "Armenia, Antioquia",
    "Barbosa, Antioquia",
    "Bello, Antioquia",
    "Belmira, Antioquia",
    "Betania, Antioquia",
    "Betulia, Antioquia",
    "Briceño, Antioquia",
    "Buriticá, Antioquia",
    "Cáceres, Antioquia",
    "Caicedo, Antioquia",
    "Caldas, Antioquia",
    "Campamento, Antioquia",
    "Cañasgordas, Antioquia",
    "Caracolí, Antioquia",
    "Caramanta, Antioquia",
    "Carepa, Antioquia",
    "Carolina, Antioquia",
    "Caucasia, Antioquia",
    "Chigorodó, Antioquia",
    "Cisneros, Antioquia",
    "Ciudad Bolívar, Antioquia",
    "Cocorná, Antioquia",
    "Concepción, Antioquia",
    "Concordia, Antioquia",
    "Copacabana, Antioquia",
    "Dabeiba, Antioquia",
    "Don Matías, Antioquia",
    "Ebéjico, Antioquia",
    "El Bagre, Antioquia",
    "El Carmen de Viboral, Antioquia",
    "El Santuario, Antioquia",
    "Entrerrios, Antioquia",
    "Envigado, Antioquia",
    "Fredonia, Antioquia",
    "Frontino, Antioquia",
    "Giraldo, Antioquia",
    "Girardota, Antioquia",
    "Gómez Plata, Antioquia",
    "Granada, Antioquia",
    "Guadalupe, Antioquia",
    "Guarne, Antioquia",
    "Guatapé, Antioquia",
    "Heliconia, Antioquia",
    "Hispania, Antioquia",
    "Itagui, Antioquia",
    "Ituango, Antioquia",
    "Jardín, Antioquia",
    "Jericó, Antioquia",
    "La Ceja, Antioquia",
    "La Estrella, Antioquia",
    "La Pintada, Antioquia",
    "La Unión, Antioquia",
    "Liborina, Antioquia",
    "Maceo, Antioquia",
    "Marinilla, Antioquia",
    "Montebello, Antioquia",
    "Murindó, Antioquia",
    "Mutatá, Antioquia",
    "Nariño, Antioquia",
    "Nechí, Antioquia",
    "Necoclí, Antioquia",
    "Olaya, Antioquia",
    "Peñol, Antioquia",
    "Peque, Antioquia",
    "Pueblorrico, Antioquia",
    "Puerto Berrío, Antioquia",
    "Puerto Nare, Antioquia",
    "Puerto Triunfo, Antioquia",
    "Remedios, Antioquia",
    "Retiro, Antioquia",
    "Rionegro, Antioquia",
    "Sabanalarga, Antioquia",
    "Sabaneta, Antioquia",
    "Salgar, Antioquia",
    "San Andrés de Cuerquía, Antioquia",
    "San Carlos, Antioquia",
    "San Francisco, Antioquia",
    "San Jerónimo, Antioquia",
    "San José de La Montaña, Antioquia",
    "San Juan de Urabá, Antioquia",
    "San Luis, Antioquia",
    "San Pedro, Antioquia",
    "San Pedro de Uraba, Antioquia",
    "San Rafael, Antioquia",
    "San Roque, Antioquia",
    "San Vicente, Antioquia",
    "Santa Bárbara, Antioquia",
    "Santa Rosa de Osos, Antioquia",
    "Santafé de Antioquia, Antioquia",
    "Santo Domingo, Antioquia",
    "Segovia, Antioquia",
    "Sonsón, Antioquia",
    "Sopetrán, Antioquia",
    "Támesis, Antioquia",
    "Tarazá, Antioquia",
    "Tarso, Antioquia",
    "Titiribí, Antioquia",
    "Toledo, Antioquia",
    "Turbo, Antioquia",
    "Uramita, Antioquia",
    "Urrao, Antioquia",
    "Valdivia, Antioquia",
    "Valparaíso, Antioquia",
    "Vegachí, Antioquia",
    "Venecia, Antioquia",
    "Vigía del Fuerte, Antioquia",
    "Yalí, Antioquia",
    "Yarumal, Antioquia",
    "Yolombó, Antioquia",
    "Yondó, Antioquia",
    "Zaragoza, Antioquia",
    "Arauca, Arauca",
    "Arauquita, Arauca",
    "Cravo Norte, Arauca",
    "Fortul, Arauca",
    "Puerto Rondón, Arauca",
    "Saravena, Arauca",
    "Tame, Arauca",
    "Providencia, Archipiélago de San Andrés, Providencia y Santa Catalina",
    "San Andrés, Archipiélago de San Andrés, Providencia y Santa Catalina",
    "Baranoa, Atlántico",
    "Campo de La Cruz, Atlántico",
    "Candelaria, Atlántico",
    "Galapa, Atlántico",
    "Juan de Acosta, Atlántico",
    "Luruaco, Atlántico",
    "Malambo, Atlántico",
    "Manatí, Atlántico",
    "Palmar de Varela, Atlántico",
    "Piojó, Atlántico",
    "Polonuevo, Atlántico",
    "Ponedera, Atlántico",
    "Puerto Colombia, Atlántico",
    "Repelón, Atlántico",
    "Sabanagrande, Atlántico",
    "Sabanalarga, Atlántico",
    "Santa Lucía, Atlántico",
    "Santo Tomás, Atlántico",
    "Soledad, Atlántico",
    "Suan, Atlántico",
    "Tubará, Atlántico",
    "Usiacurí, Atlántico",
    "Achí, Bolívar",
    "Altos del Rosario, Bolívar",
    "Arenal, Bolívar",
    "Arjona, Bolívar",
    "Arroyohondo, Bolívar",
    "Barranco de Loba, Bolívar",
    "Calamar, Bolívar",
    "Cantagallo, Bolívar",
    "Cicuco, Bolívar",
    "Clemencia, Bolívar",
    "Córdoba, Bolívar",
    "El Carmen de Bolívar, Bolívar",
    "El Guamo, Bolívar",
    "El Peñón, Bolívar",
    "Hatillo de Loba, Bolívar",
    "Magangué, Bolívar",
    "Mahates, Bolívar",
    "Margarita, Bolívar",
    "María la Baja, Bolívar",
    "Mompós, Bolívar",
    "Montecristo, Bolívar",
    "Morales, Bolívar",
    "Norosí, Bolívar",
    "Pinillos, Bolívar",
    "Regidor, Bolívar",
    "Río Viejo, Bolívar",
    "San Cristóbal, Bolívar",
    "San Estanislao, Bolívar",
    "San Fernando, Bolívar",
    "San Jacinto, Bolívar",
    "San Jacinto del Cauca, Bolívar",
    "San Juan Nepomuceno, Bolívar",
    "San Martín de Loba, Bolívar",
    "San Pablo de Borbur, Bolívar",
    "Santa Catalina, Bolívar",
    "Santa Rosa, Bolívar",
    "Santa Rosa del Sur, Bolívar",
    "Simití, Bolívar",
    "Soplaviento, Bolívar",
    "Talaigua Nuevo, Bolívar",
    "Tiquisio, Bolívar",
    "Turbaco, Bolívar",
    "Turbaná, Bolívar",
    "Villanueva, Bolívar",
    "Zambrano, Bolívar",
    "Almeida, Boyacá",
    "Aquitania, Boyacá",
    "Arcabuco, Boyacá",
    "Belén, Boyacá",
    "Berbeo, Boyacá",
    "Betéitiva, Boyacá",
    "Boavita, Boyacá",
    "Boyacá, Boyacá",
    "Briceño, Boyacá",
    "Buena Vista, Boyacá",
    "Busbanzá, Boyacá",
    "Caldas, Boyacá",
    "Campohermoso, Boyacá",
    "Cerinza, Boyacá",
    "Chinavita, Boyacá",
    "Chiquinquirá, Boyacá",
    "Chíquiza, Boyacá",
    "Chiscas, Boyacá",
    "Chita, Boyacá",
    "Chitaraque, Boyacá",
    "Chivatá, Boyacá",
    "Chivor, Boyacá",
    "Ciénega, Boyacá",
    "Cómbita, Boyacá",
    "Coper, Boyacá",
    "Corrales, Boyacá",
    "Covarachía, Boyacá",
    "Cubará, Boyacá",
    "Cucaita, Boyacá",
    "Cuítiva, Boyacá",
    "Duitama, Boyacá",
    "El Cocuy, Boyacá",
    "El Espino, Boyacá",
    "Firavitoba, Boyacá",
    "Floresta, Boyacá",
    "Gachantivá, Boyacá",
    "Gameza, Boyacá",
    "Garagoa, Boyacá",
    "Guacamayas, Boyacá",
    "Guateque, Boyacá",
    "Guayatá, Boyacá",
    "Güicán, Boyacá",
    "Iza, Boyacá",
    "Jenesano, Boyacá",
    "Jericó, Boyacá",
    "La Capilla, Boyacá",
    "La Uvita, Boyacá",
    "La Victoria, Boyacá",
    "Labranzagrande, Boyacá",
    "Macanal, Boyacá",
    "Maripí, Boyacá",
    "Miraflores, Boyacá",
    "Mongua, Boyacá",
    "Monguí, Boyacá",
    "Moniquirá, Boyacá",
    "Motavita, Boyacá",
    "Muzo, Boyacá",
    "Nobsa, Boyacá",
    "Nuevo Colón, Boyacá",
    "Oicatá, Boyacá",
    "Otanche, Boyacá",
    "Pachavita, Boyacá",
    "Páez, Boyacá",
    "Paipa, Boyacá",
    "Pajarito, Boyacá",
    "Panqueba, Boyacá",
    "Pauna, Boyacá",
    "Paya, Boyacá",
    "Paz de Río, Boyacá",
    "Pesca, Boyacá",
    "Pisba, Boyacá",
    "Puerto Boyacá, Boyacá",
    "Quípama, Boyacá",
    "Ramiriquí, Boyacá",
    "Ráquira, Boyacá",
    "Rondón, Boyacá",
    "Saboyá, Boyacá",
    "Sáchica, Boyacá",
    "Samacá, Boyacá",
    "San Eduardo, Boyacá",
    "San José de Pare, Boyacá",
    "San Luis de Gaceno, Boyacá",
    "San Mateo, Boyacá",
    "San Miguel de Sema, Boyacá",
    "San Pablo de Borbur, Boyacá",
    "Santa María, Boyacá",
    "Santa Rosa de Viterbo, Boyacá",
    "Santa Sofía, Boyacá",
    "Santana, Boyacá",
    "Sativanorte, Boyacá",
    "Sativasur, Boyacá",
    "Siachoque, Boyacá",
    "Soatá, Boyacá",
    "Socha, Boyacá",
    "Socotá, Boyacá",
    "Sogamoso, Boyacá",
    "Somondoco, Boyacá",
    "Sora, Boyacá",
    "Soracá, Boyacá",
    "Sotaquirá, Boyacá",
    "Susacón, Boyacá",
    "Sutamarchán, Boyacá",
    "Sutatenza, Boyacá",
    "Tasco, Boyacá",
    "Tenza, Boyacá",
    "Tibaná, Boyacá",
    "Tibasosa, Boyacá",
    "Tinjacá, Boyacá",
    "Tipacoque, Boyacá",
    "Toca, Boyacá",
    "Togüí, Boyacá",
    "Tópaga, Boyacá",
    "Tota, Boyacá",
    "Tunja, Boyacá",
    "Tununguá, Boyacá",
    "Turmequé, Boyacá",
    "Tuta, Boyacá",
    "Tutazá, Boyacá",
    "Umbita, Boyacá",
    "Ventaquemada, Boyacá",
    "Villa de Leyva, Boyacá",
    "Viracachá, Boyacá",
    "Zetaquira, Boyacá",
    "Aguadas, Caldas",
    "Anserma, Caldas",
    "Aranzazu, Caldas",
    "Belalcázar, Caldas",
    "Chinchiná, Caldas",
    "Filadelfia, Caldas",
    "La Dorada, Caldas",
    "La Merced, Caldas",
    "Manzanares, Caldas",
    "Marmato, Caldas",
    "Marquetalia, Caldas",
    "Marulanda, Caldas",
    "Neira, Caldas",
    "Norcasia, Caldas",
    "Pácora, Caldas",
    "Palestina, Caldas",
    "Pensilvania, Caldas",
    "Riosucio, Caldas",
    "Risaralda, Caldas",
    "Salamina, Caldas",
    "Samaná, Caldas",
    "San José, Caldas",
    "Supía, Caldas",
    "Victoria, Caldas",
    "Villamaría, Caldas",
    "Viterbo, Caldas",
    "Albania, Caquetá",
    "Belén de Los Andaquies, Caquetá",
    "Cartagena del Chairá, Caquetá",
    "Curillo, Caquetá",
    "El Doncello, Caquetá",
    "El Paujil, Caquetá",
    "Florencia, Caquetá",
    "La Montañita, Caquetá",
    "Milán, Caquetá",
    "Morelia, Caquetá",
    "Puerto Rico, Caquetá",
    "San José del Fragua, Caquetá",
    "San Vicente del Caguán, Caquetá",
    "Solano, Caquetá",
    "Solita, Caquetá",
    "Valparaíso, Caquetá",
    "Aguazul, Casanare",
    "Chámeza, Casanare",
    "Hato Corozal, Casanare",
    "La Salina, Casanare",
    "Maní, Casanare",
    "Monterrey, Casanare",
    "Nunchía, Casanare",
    "Orocué, Casanare",
    "Paz de Ariporo, Casanare",
    "Pore, Casanare",
    "Recetor, Casanare",
    "Sabanalarga, Casanare",
    "Sácama, Casanare",
    "San Luis de Gaceno, Casanare",
    "Támara, Casanare",
    "Tauramena, Casanare",
    "Trinidad, Casanare",
    "Villanueva, Casanare",
    "Yopal, Casanare",
    "Almaguer, Cauca",
    "Argelia, Cauca",
    "Balboa, Cauca",
    "Bolívar, Cauca",
    "Buenos Aires, Cauca",
    "Cajibío, Cauca",
    "Caldono, Cauca",
    "Caloto, Cauca",
    "Corinto, Cauca",
    "El Tambo, Cauca",
    "Florencia, Cauca",
    "Guachené, Cauca",
    "Guapi, Cauca",
    "Inzá, Cauca",
    "Jambaló, Cauca",
    "La Sierra, Cauca",
    "La Vega, Cauca",
    "López, Cauca",
    "Mercaderes, Cauca",
    "Miranda, Cauca",
    "Morales, Cauca",
    "Padilla, Cauca",
    "Páez, Cauca",
    "Patía, Cauca",
    "Piamonte, Cauca",
    "Piendamó, Cauca",
    "Popayán, Cauca",
    "Puerto Tejada, Cauca",
    "Puracé, Cauca",
    "Rosas, Cauca",
    "San Sebastián, Cauca",
    "Santa Rosa, Cauca",
    "Santander de Quilichao, Cauca",
    "Silvia, Cauca",
    "Sotara, Cauca",
    "Suárez, Cauca",
    "Sucre, Cauca",
    "Timbío, Cauca",
    "Timbiquí, Cauca",
    "Toribio, Cauca",
    "Totoró, Cauca",
    "Villa Rica, Cauca",
    "Aguachica, Cesar",
    "Agustín Codazzi, Cesar",
    "Astrea, Cesar",
    "Becerril, Cesar",
    "Bosconia, Cesar",
    "Chimichagua, Cesar",
    "Chiriguaná, Cesar",
    "Curumaní, Cesar",
    "El Copey, Cesar",
    "El Paso, Cesar",
    "Gamarra, Cesar",
    "González, Cesar",
    "La Gloria, Cesar",
    "La Jagua de Ibirico, Cesar",
    "La Paz, Cesar",
    "Manaure, Cesar",
    "Pailitas, Cesar",
    "Pelaya, Cesar",
    "Pueblo Bello, Cesar",
    "Río de Oro, Cesar",
    "San Alberto, Cesar",
    "San Diego, Cesar",
    "San Martín, Cesar",
    "Tamalameque, Cesar",
    "Acandí, Chocó",
    "Alto Baudo, Chocó",
    "Atrato, Chocó",
    "Bagadó, Chocó",
    "Bahía Solano, Chocó",
    "Bajo Baudó, Chocó",
    "Belén de Bajira, Chocó",
    "Bojaya, Chocó",
    "Carmen del Darien, Chocó",
    "Cértegui, Chocó",
    "Condoto, Chocó",
    "El Cantón del San Pablo, Chocó",
    "El Carmen de Atrato, Chocó",
    "El Litoral del San Juan, Chocó",
    "Istmina, Chocó",
    "Juradó, Chocó",
    "Lloró, Chocó",
    "Medio Atrato, Chocó",
    "Medio Baudó, Chocó",
    "Medio San Juan, Chocó",
    "Nóvita, Chocó",
    "Nuquí, Chocó",
    "Quibdó, Chocó",
    "Río Iro, Chocó",
    "Río Quito, Chocó",
    "Riosucio, Chocó",
    "San José del Palmar, Chocó",
    "Sipí, Chocó",
    "Tadó, Chocó",
    "Unguía, Chocó",
    "Unión Panamericana, Chocó",
    "Ayapel, Córdoba",
    "Buenavista, Córdoba",
    "Canalete, Córdoba",
    "Cereté, Córdoba",
    "Chimá, Córdoba",
    "Chinú, Córdoba",
    "Ciénaga de Oro, Córdoba",
    "Cotorra, Córdoba",
    "La Apartada, Córdoba",
    "Lorica, Córdoba",
    "Los Córdobas, Córdoba",
    "Momil, Córdoba",
    "Moñitos, Córdoba",
    "Montelíbano, Córdoba",
    "Montería, Córdoba",
    "Planeta Rica, Córdoba",
    "Pueblo Nuevo, Córdoba",
    "Puerto Escondido, Córdoba",
    "Puerto Libertador, Córdoba",
    "Purísima, Córdoba",
    "Sahagún, Córdoba",
    "San Andrés Sotavento, Córdoba",
    "San Antero, Córdoba",
    "San Bernardo del Viento, Córdoba",
    "San Carlos, Córdoba",
    "San José de Uré, Córdoba",
    "San Pelayo, Córdoba",
    "Tierralta, Córdoba",
    "Tuchín, Córdoba",
    "Valencia, Córdoba",
    "Agua de Dios, Cundinamarca",
    "Albán, Cundinamarca",
    "Anapoima, Cundinamarca",
    "Anolaima, Cundinamarca",
    "Apulo, Cundinamarca",
    "Arbeláez, Cundinamarca",
    "Beltrán, Cundinamarca",
    "Bituima, Cundinamarca",
    "Bojacá, Cundinamarca",
    "Cabrera, Cundinamarca",
    "Cachipay, Cundinamarca",
    "Cajicá, Cundinamarca",
    "Caparrapí, Cundinamarca",
    "Caqueza, Cundinamarca",
    "Carmen de Carupa, Cundinamarca",
    "Chaguaní, Cundinamarca",
    "Chía, Cundinamarca",
    "Chipaque, Cundinamarca",
    "Choachí, Cundinamarca",
    "Chocontá, Cundinamarca",
    "Cogua, Cundinamarca",
    "Cota, Cundinamarca",
    "Cucunubá, Cundinamarca",
    "El Colegio, Cundinamarca",
    "El Peñón, Cundinamarca",
    "El Rosal, Cundinamarca",
    "Facatativá, Cundinamarca",
    "Fomeque, Cundinamarca",
    "Fosca, Cundinamarca",
    "Funza, Cundinamarca",
    "Fúquene, Cundinamarca",
    "Fusagasugá, Cundinamarca",
    "Gachala, Cundinamarca",
    "Gachancipá, Cundinamarca",
    "Gachetá, Cundinamarca",
    "Gama, Cundinamarca",
    "Girardot, Cundinamarca",
    "Granada, Cundinamarca",
    "Guachetá, Cundinamarca",
    "Guaduas, Cundinamarca",
    "Guasca, Cundinamarca",
    "Guataquí, Cundinamarca",
    "Guatavita, Cundinamarca",
    "Guayabal de Siquima, Cundinamarca",
    "Guayabetal, Cundinamarca",
    "Gutiérrez, Cundinamarca",
    "Jerusalén, Cundinamarca",
    "Junín, Cundinamarca",
    "La Calera, Cundinamarca",
    "La Mesa, Cundinamarca",
    "La Palma, Cundinamarca",
    "La Peña, Cundinamarca",
    "La Vega, Cundinamarca",
    "Lenguazaque, Cundinamarca",
    "Macheta, Cundinamarca",
    "Madrid, Cundinamarca",
    "Manta, Cundinamarca",
    "Medina, Cundinamarca",
    "Mosquera, Cundinamarca",
    "Nariño, Cundinamarca",
    "Nemocón, Cundinamarca",
    "Nilo, Cundinamarca",
    "Nimaima, Cundinamarca",
    "Nocaima, Cundinamarca",
    "Pacho, Cundinamarca",
    "Paime, Cundinamarca",
    "Pandi, Cundinamarca",
    "Paratebueno, Cundinamarca",
    "Pasca, Cundinamarca",
    "Puerto Salgar, Cundinamarca",
    "Pulí, Cundinamarca",
    "Quebradanegra, Cundinamarca",
    "Quetame, Cundinamarca",
    "Quipile, Cundinamarca",
    "Ricaurte, Cundinamarca",
    "San Antonio del Tequendama, Cundinamarca",
    "San Bernardo, Cundinamarca",
    "San Cayetano, Cundinamarca",
    "San Francisco, Cundinamarca",
    "San Juan de Río Seco, Cundinamarca",
    "Sasaima, Cundinamarca",
    "Sesquilé, Cundinamarca",
    "Sibaté, Cundinamarca",
    "Silvania, Cundinamarca",
    "Simijaca, Cundinamarca",
    "Soacha, Cundinamarca",
    "Sopó, Cundinamarca",
    "Subachoque, Cundinamarca",
    "Suesca, Cundinamarca",
    "Supatá, Cundinamarca",
    "Susa, Cundinamarca",
    "Sutatausa, Cundinamarca",
    "Tabio, Cundinamarca",
    "Tausa, Cundinamarca",
    "Tena, Cundinamarca",
    "Tenjo, Cundinamarca",
    "Tibacuy, Cundinamarca",
    "Tibirita, Cundinamarca",
    "Tocaima, Cundinamarca",
    "Tocancipá, Cundinamarca",
    "Topaipí, Cundinamarca",
    "Ubalá, Cundinamarca",
    "Ubaque, Cundinamarca",
    "Une, Cundinamarca",
    "Útica, Cundinamarca",
    "Venecia, Cundinamarca",
    "Vergara, Cundinamarca",
    "Vianí, Cundinamarca",
    "Villa de San Diego de Ubate, Cundinamarca",
    "Villagómez, Cundinamarca",
    "Villapinzón, Cundinamarca",
    "Villeta, Cundinamarca",
    "Viotá, Cundinamarca",
    "Yacopí, Cundinamarca",
    "Zipacón, Cundinamarca",
    "Zipaquirá, Cundinamarca",
    "Barranco Minas, Guainía",
    "Cacahual, Guainía",
    "Inírida, Guainía",
    "La Guadalupe, Guainía",
    "Mapiripana, Guainía",
    "Morichal, Guainía",
    "Pana Pana, Guainía",
    "Puerto Colombia, Guainía",
    "San Felipe, Guainía",
    "Calamar, Guaviare",
    "El Retorno, Guaviare",
    "Miraflores, Guaviare",
    "San José del Guaviare, Guaviare",
    "Acevedo, Huila",
    "Agrado, Huila",
    "Aipe, Huila",
    "Algeciras, Huila",
    "Altamira, Huila",
    "Baraya, Huila",
    "Campoalegre, Huila",
    "Colombia, Huila",
    "Elías, Huila",
    "Garzón, Huila",
    "Gigante, Huila",
    "Guadalupe, Huila",
    "Hobo, Huila",
    "Iquira, Huila",
    "Isnos, Huila",
    "La Argentina, Huila",
    "La Plata, Huila",
    "Nátaga, Huila",
    "Neiva, Huila",
    "Oporapa, Huila",
    "Paicol, Huila",
    "Palermo, Huila",
    "Palestina, Huila",
    "Pital, Huila",
    "Pitalito, Huila",
    "Rivera, Huila",
    "Saladoblanco, Huila",
    "San Agustín, Huila",
    "Santa María, Huila",
    "Suaza, Huila",
    "Tarqui, Huila",
    "Tello, Huila",
    "Teruel, Huila",
    "Tesalia, Huila",
    "Timaná, Huila",
    "Villavieja, Huila",
    "Yaguará, Huila",
    "Albania, La Guajira",
    "Barrancas, La Guajira",
    "Dibula, La Guajira",
    "Distracción, La Guajira",
    "El Molino, La Guajira",
    "Fonseca, La Guajira",
    "Hatonuevo, La Guajira",
    "La Jagua del Pilar, La Guajira",
    "Maicao, La Guajira",
    "Manaure, La Guajira",
    "Riohacha, La Guajira",
    "San Juan del Cesar, La Guajira",
    "Uribia, La Guajira",
    "Urumita, La Guajira",
    "Villanueva, La Guajira",
    "Algarrobo, Magdalena",
    "Aracataca, Magdalena",
    "Ariguaní, Magdalena",
    "Cerro San Antonio, Magdalena",
    "Chivolo, Magdalena",
    "Ciénaga, Magdalena",
    "Concordia, Magdalena",
    "El Banco, Magdalena",
    "El Piñon, Magdalena",
    "El Retén, Magdalena",
    "Fundación, Magdalena",
    "Guamal, Magdalena",
    "Nueva Granada, Magdalena",
    "Pedraza, Magdalena",
    "Pijiño del Carmen, Magdalena",
    "Pivijay, Magdalena",
    "Plato, Magdalena",
    "Pueblo Viejo, Magdalena",
    "Remolino, Magdalena",
    "Sabanas de San Angel, Magdalena",
    "Salamina, Magdalena",
    "San Sebastián de Buenavista, Magdalena",
    "San Zenón, Magdalena",
    "Santa Ana, Magdalena",
    "Santa Bárbara de Pinto, Magdalena",
    "Sitionuevo, Magdalena",
    "Tenerife, Magdalena",
    "Zapayán, Magdalena",
    "Zona Bananera, Magdalena",
    "Acacias, Meta",
    "Barranca de Upía, Meta",
    "Cabuyaro, Meta",
    "Castilla la Nueva, Meta",
    "Cubarral, Meta",
    "Cumaral, Meta",
    "El Calvario, Meta",
    "El Castillo, Meta",
    "El Dorado, Meta",
    "Fuente de Oro, Meta",
    "Granada, Meta",
    "Guamal, Meta",
    "La Macarena, Meta",
    "Lejanías, Meta",
    "Mapiripán, Meta",
    "Mesetas, Meta",
    "Puerto Concordia, Meta",
    "Puerto Gaitán, Meta",
    "Puerto Lleras, Meta",
    "Puerto López, Meta",
    "Puerto Rico, Meta",
    "Restrepo, Meta",
    "San Carlos de Guaroa, Meta",
    "San Juan de Arama, Meta",
    "San Juanito, Meta",
    "San Martín, Meta",
    "Uribe, Meta",
    "Vista Hermosa, Meta",
    "Albán, Nariño",
    "Aldana, Nariño",
    "Ancuyá, Nariño",
    "Arboleda, Nariño",
    "Barbacoas, Nariño",
    "Belén, Nariño",
    "Buesaco, Nariño",
    "Chachagüí, Nariño",
    "Colón, Nariño",
    "Consaca, Nariño",
    "Contadero, Nariño",
    "Córdoba, Nariño",
    "Cuaspud, Nariño",
    "Cumbal, Nariño",
    "Cumbitara, Nariño",
    "El Charco, Nariño",
    "El Peñol, Nariño",
    "El Rosario, Nariño",
    "El Tablón de Gómez, Nariño",
    "El Tambo, Nariño",
    "Francisco Pizarro, Nariño",
    "Funes, Nariño",
    "Guachucal, Nariño",
    "Guaitarilla, Nariño",
    "Gualmatán, Nariño",
    "Iles, Nariño",
    "Imués, Nariño",
    "Ipiales, Nariño",
    "La Cruz, Nariño",
    "La Florida, Nariño",
    "La Llanada, Nariño",
    "La Tola, Nariño",
    "La Unión, Nariño",
    "Leiva, Nariño",
    "Linares, Nariño",
    "Los Andes, Nariño",
    "Magüí, Nariño",
    "Mallama, Nariño",
    "Mosquera, Nariño",
    "Nariño, Nariño",
    "Olaya Herrera, Nariño",
    "Ospina, Nariño",
    "Pasto, Nariño",
    "Policarpa, Nariño",
    "Potosí, Nariño",
    "Providencia, Nariño",
    "Puerres, Nariño",
    "Pupiales, Nariño",
    "Ricaurte, Nariño",
    "Roberto Payán, Nariño",
    "Samaniego, Nariño",
    "San Andrés de Tumaco, Nariño",
    "San Bernardo, Nariño",
    "San Lorenzo, Nariño",
    "San Pablo, Nariño",
    "San Pedro de Cartago, Nariño",
    "Sandoná, Nariño",
    "Santa Bárbara, Nariño",
    "Santacruz, Nariño",
    "Sapuyes, Nariño",
    "Taminango, Nariño",
    "Tangua, Nariño",
    "Túquerres, Nariño",
    "Yacuanquer, Nariño",
    "Abrego, Norte de Santander",
    "Arboledas, Norte de Santander",
    "Bochalema, Norte de Santander",
    "Bucarasica, Norte de Santander",
    "Cachirá, Norte de Santander",
    "Cácota, Norte de Santander",
    "Chinácota, Norte de Santander",
    "Chitagá, Norte de Santander",
    "Convención, Norte de Santander",
    "Cucutilla, Norte de Santander",
    "Durania, Norte de Santander",
    "El Carmen, Norte de Santander",
    "El Tarra, Norte de Santander",
    "El Zulia, Norte de Santander",
    "Gramalote, Norte de Santander",
    "Hacarí, Norte de Santander",
    "Herrán, Norte de Santander",
    "La Esperanza, Norte de Santander",
    "La Playa, Norte de Santander",
    "Labateca, Norte de Santander",
    "Los Patios, Norte de Santander",
    "Lourdes, Norte de Santander",
    "Mutiscua, Norte de Santander",
    "Ocaña, Norte de Santander",
    "Pamplona, Norte de Santander",
    "Pamplonita, Norte de Santander",
    "Puerto Santander, Norte de Santander",
    "Ragonvalia, Norte de Santander",
    "Salazar, Norte de Santander",
    "San Calixto, Norte de Santander",
    "San Cayetano, Norte de Santander",
    "Santiago, Norte de Santander",
    "Sardinata, Norte de Santander",
    "Silos, Norte de Santander",
    "Teorama, Norte de Santander",
    "Tibú, Norte de Santander",
    "Toledo, Norte de Santander",
    "Villa Caro, Norte de Santander",
    "Villa del Rosario, Norte de Santander",
    "Colón, Putumayo",
    "Leguízamo, Putumayo",
    "Mocoa, Putumayo",
    "Orito, Putumayo",
    "Puerto Asís, Putumayo",
    "Puerto Caicedo, Putumayo",
    "Puerto Guzmán, Putumayo",
    "San Francisco, Putumayo",
    "San Miguel, Putumayo",
    "Santiago, Putumayo",
    "Sibundoy, Putumayo",
    "Valle de Guamez, Putumayo",
    "Villagarzón, Putumayo",
    "Armenia, Quindío",
    "Buenavista, Quindío",
    "Calarcá, Quindío",
    "Circasia, Quindío",
    "Córdoba, Quindío",
    "Filandia, Quindío",
    "Génova, Quindío",
    "La Tebaida, Quindío",
    "Montenegro, Quindío",
    "Pijao, Quindío",
    "Quimbaya, Quindío",
    "Salento, Quindío",
    "Apía, Risaralda",
    "Balboa, Risaralda",
    "Belén de Umbría, Risaralda",
    "Dosquebradas, Risaralda",
    "Guática, Risaralda",
    "La Celia, Risaralda",
    "La Virginia, Risaralda",
    "Marsella, Risaralda",
    "Mistrató, Risaralda",
    "Pueblo Rico, Risaralda",
    "Quinchía, Risaralda",
    "Santa Rosa de Cabal, Risaralda",
    "Santuario, Risaralda",
    "Aguada, Santander",
    "Albania, Santander",
    "Aratoca, Santander",
    "Barbosa, Santander",
    "Barichara, Santander",
    "Barrancabermeja, Santander",
    "Betulia, Santander",
    "Bolívar, Santander",
    "Cabrera, Santander",
    "California, Santander",
    "Capitanejo, Santander",
    "Carcasí, Santander",
    "Cepitá, Santander",
    "Cerrito, Santander",
    "Charalá, Santander",
    "Charta, Santander",
    "Chimá, Santander",
    "Chipatá, Santander",
    "Cimitarra, Santander",
    "Concepción, Santander",
    "Confines, Santander",
    "Contratación, Santander",
    "Coromoro, Santander",
    "Curití, Santander",
    "El Carmen de Chucurí, Santander",
    "El Guacamayo, Santander",
    "El Peñón, Santander",
    "El Playón, Santander",
    "Encino, Santander",
    "Enciso, Santander",
    "Florián, Santander",
    "Floridablanca, Santander",
    "Galán, Santander",
    "Gambita, Santander",
    "Girón, Santander",
    "Guaca, Santander",
    "Guadalupe, Santander",
    "Guapotá, Santander",
    "Guavatá, Santander",
    "Güepsa, Santander",
    "Hato, Santander",
    "Jesús María, Santander",
    "Jordán, Santander",
    "La Belleza, Santander",
    "La Paz, Santander",
    "Landázuri, Santander",
    "Lebríja, Santander",
    "Los Santos, Santander",
    "Macaravita, Santander",
    "Málaga, Santander",
    "Matanza, Santander",
    "Mogotes, Santander",
    "Molagavita, Santander",
    "Ocamonte, Santander",
    "Oiba, Santander",
    "Onzaga, Santander",
    "Palmar, Santander",
    "Palmas del Socorro, Santander",
    "Páramo, Santander",
    "Piedecuesta, Santander",
    "Pinchote, Santander",
    "Puente Nacional, Santander",
    "Puerto Parra, Santander",
    "Puerto Wilches, Santander",
    "Rionegro, Santander",
    "Sabana de Torres, Santander",
    "San Andrés, Santander",
    "San Benito, Santander",
    "San Gil, Santander",
    "San Joaquín, Santander",
    "San José de Miranda, Santander",
    "San Miguel, Santander",
    "San Vicente de Chucurí, Santander",
    "Santa Bárbara, Santander",
    "Santa Helena del Opón, Santander",
    "Simacota, Santander",
    "Socorro, Santander",
    "Suaita, Santander",
    "Sucre, Santander",
    "Suratá, Santander",
    "Tona, Santander",
    "Valle de San José, Santander",
    "Vélez, Santander",
    "Vetas, Santander",
    "Villanueva, Santander",
    "Zapatoca, Santander",
    "Buenavista, Sucre",
    "Caimito, Sucre",
    "Chalán, Sucre",
    "Coloso, Sucre",
    "Corozal, Sucre",
    "Coveñas, Sucre",
    "El Roble, Sucre",
    "Galeras, Sucre",
    "Guaranda, Sucre",
    "La Unión, Sucre",
    "Los Palmitos, Sucre",
    "Majagual, Sucre",
    "Morroa, Sucre",
    "Ovejas, Sucre",
    "Palmito, Sucre",
    "Sampués, Sucre",
    "San Benito Abad, Sucre",
    "San Juan de Betulia, Sucre",
    "San Luis de Sincé, Sucre",
    "San Marcos, Sucre",
    "San Onofre, Sucre",
    "San Pedro, Sucre",
    "Santiago de Tolú, Sucre",
    "Sincelejo, Sucre",
    "Sucre, Sucre",
    "Tolú Viejo, Sucre",
    "Alpujarra, Tolima",
    "Alvarado, Tolima",
    "Ambalema, Tolima",
    "Anzoátegui, Tolima",
    "Armero, Tolima",
    "Ataco, Tolima",
    "Cajamarca, Tolima",
    "Carmen de Apicala, Tolima",
    "Casabianca, Tolima",
    "Chaparral, Tolima",
    "Coello, Tolima",
    "Coyaima, Tolima",
    "Cunday, Tolima",
    "Dolores, Tolima",
    "Espinal, Tolima",
    "Falan, Tolima",
    "Flandes, Tolima",
    "Fresno, Tolima",
    "Guamo, Tolima",
    "Herveo, Tolima",
    "Honda, Tolima",
    "Icononzo, Tolima",
    "Lérida, Tolima",
    "Líbano, Tolima",
    "Mariquita, Tolima",
    "Melgar, Tolima",
    "Murillo, Tolima",
    "Natagaima, Tolima",
    "Ortega, Tolima",
    "Palocabildo, Tolima",
    "Piedras, Tolima",
    "Planadas, Tolima",
    "Prado, Tolima",
    "Purificación, Tolima",
    "Rio Blanco, Tolima",
    "Roncesvalles, Tolima",
    "Rovira, Tolima",
    "Saldaña, Tolima",
    "San Antonio, Tolima",
    "San Luis, Tolima",
    "Santa Isabel, Tolima",
    "Suárez, Tolima",
    "Valle de San Juan, Tolima",
    "Venadillo, Tolima",
    "Villahermosa, Tolima",
    "Villarrica, Tolima",
    "Alcalá, Valle del Cauca",
    "Andalucía, Valle del Cauca",
    "Ansermanuevo, Valle del Cauca",
    "Argelia, Valle del Cauca",
    "Bolívar, Valle del Cauca",
    "Buenaventura, Valle del Cauca",
    "Bugalagrande, Valle del Cauca",
    "Caicedonia, Valle del Cauca",
    "Calima, Valle del Cauca",
    "Candelaria, Valle del Cauca",
    "Cartago, Valle del Cauca",
    "Dagua, Valle del Cauca",
    "El Águila, Valle del Cauca",
    "El Cairo, Valle del Cauca",
    "El Cerrito, Valle del Cauca",
    "El Dovio, Valle del Cauca",
    "Florida, Valle del Cauca",
    "Ginebra, Valle del Cauca",
    "Guacarí, Valle del Cauca",
    "Guadalajara de Buga, Valle del Cauca",
    "Jamundí, Valle del Cauca",
    "La Cumbre, Valle del Cauca",
    "La Unión, Valle del Cauca",
    "La Victoria, Valle del Cauca",
    "Obando, Valle del Cauca",
    "Palmira, Valle del Cauca",
    "Pradera, Valle del Cauca",
    "Restrepo, Valle del Cauca",
    "Riofrío, Valle del Cauca",
    "Roldanillo, Valle del Cauca",
    "San Pedro, Valle del Cauca",
    "Sevilla, Valle del Cauca",
    "Toro, Valle del Cauca",
    "Trujillo, Valle del Cauca",
    "Tuluá, Valle del Cauca",
    "Ulloa, Valle del Cauca",
    "Versalles, Valle del Cauca",
    "Vijes, Valle del Cauca",
    "Yotoco, Valle del Cauca",
    "Yumbo, Valle del Cauca",
    "Zarzal, Valle del Cauca",
    "Caruru, Vaupés",
    "Mitú, Vaupés",
    "Pacoa, Vaupés",
    "Papunaua, Vaupés",
    "Taraira, Vaupés",
    "Yavaraté, Vaupés",
    "Cumaribo, Vichada",
    "La Primavera, Vichada",
    "Puerto Carreño, Vichada",
    "Santa Rosalía, Vichada",
]

export default cities;