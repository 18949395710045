import Colors from '../../constants/Colors';
import Container from '@material-ui/core/Container';
import CreditType from './CreditType';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import data from '../../data/directrices.json';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    marginTop: '-1%',
    padding: '15% 15% 5%',
    backgroundColor: Colors.primary,
    [theme.breakpoints.down('sm')]: {
      padding: '5% 10%'
    }
  },
  mainTitle: {
    color: Colors.secondary,
    textAlign: 'left',
    fontSize: '2.5em',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9em',
      textAlign: 'center'
    }
  },
  secondaryTitle: {
    color: Colors.white,
    textAlign: 'left',
    fontSize: '2.1em',
    fontWeight: 200,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.6em'
    }
  },
  mainSubTitle: {
    color: Colors.primary,
    textAlign: 'center',
    margin: '2%',
    marginBottom: '4%',
    fontSize: '20px'
  }
}));

const CreditsButtons = (props) => {
  const classes = useStyles();

  return (
    <Container className={classes.container} maxWidth={false}>
      <Grid container spacing={3} alignItems="center" justify="center">
        <Grid item xs={12} sm={6}>
          <Typography className={classes.mainTitle}>Encuentra tu crédito</Typography>
          <Typography className={classes.secondaryTitle}>La mejor manera de hacer realidad tus sueños.</Typography>
        </Grid>

        {data.map((creditType, key) => {
          if (creditType.url !== 'rapido') {
            return (
              <Grid key={key} item xs={12} sm={6}>
                <CreditType name={creditType.name} url={creditType.url} style={{ textAlign: 'center' }} />
              </Grid>
            );
          }
          else return null;
        })}
      </Grid>
    </Container>
  );
};

export default CreditsButtons;
