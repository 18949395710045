import { Box, Checkbox, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  accountNumber: {
    marginTop: '5%'
  }
}));

export default function Accounts({
  accounts,
  selectedAccount,
  setSelectedAccount,
  automaticDebit,
  setAutomaticDebit,
  accountNumber,
  setAccountNumber
}) {
  const classes = useStyles();
  const [accountNumberError, setAccountNumberError] = useState(false);

  const handleToggle = (value) => () => {
    const newChecked = { ...value };
    setSelectedAccount(newChecked);
  };

  const handleChangeAccountNumber = (event) => {
    setAccountNumber(event.target.value);
  };

  useEffect(() => {
    if (selectedAccount && accountNumber) {
      const selectedAccountNumber = selectedAccount.number.toString();
      const last4DigitsSA = selectedAccountNumber.substr(selectedAccountNumber.length - 4);
      const last4DigitsInput = accountNumber.substr(accountNumber.length - 4);
      if (last4DigitsInput === last4DigitsSA) setAccountNumberError(false);
      else setAccountNumberError(true);
    }
  }, [selectedAccount, accountNumber]);

  return (
    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" width="100%">
      <Typography component="p" variant="body1" align="center" className={classes.gray}>
        <strong>¿En qué cuenta hacemos el desembolso?</strong>
      </Typography>
      <List dense className={classes.list}>
        {accounts.map((account, index) => {
          const num = account.number.toString();
          return (
            <ListItem key={index} button onClick={handleToggle(account)}>
              <ListItemText
                id={index}
                primary={account.bank}
                secondary={`Terminada en ${num.substr(num.length - 4)}`}
              />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  color="primary"
                  onChange={handleToggle(account)}
                  checked={selectedAccount.number === account.number && selectedAccount.bank === account.bank}
                  inputProps={{ 'aria-labelledby': index }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
        {Object.keys(selectedAccount).length > 0 && (
          <div className={classes.accountNumber}>
            <Typography component="p" variant="body1" align="center" className={classes.gray}>
              Por favor confirma el número de tu cuenta. Sin puntos, guiones o comas.
            </Typography>
            <TextField
              id="accountNumber"
              name="accountNumber"
              label="Número de cuenta"
              fullWidth
              autoComplete="accountNumber"
              value={accountNumber || ''}
              error={accountNumberError}
              helperText={accountNumberError ? 'Revisa que el número esté correcto' : null}
              onChange={handleChangeAccountNumber}
            />
          </div>
        )}
        {accounts.length === 0 && (
          <Typography component="p" variant="body1" align="center" className={classes.gray}>
            Necesitas una cuenta de ahorros o corriente para recibir el desembolso
          </Typography>
        )}
      </List>
    </Box>
  );
}
