import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useCallback, useEffect } from 'react';
import MediaQuery from 'react-responsive';
import ResourceDestination from '../ResourceDestination';
import AmountSlider from '../utils/AmountSlider';
import DeadlineSlider from '../utils/DeadlineSlider';


const initialFieldCount = 4;

export default function CorporateCredit(props) {
  let {
    attribute,
    data,
    errors,
    handleChange,
    handleCustomChange,
    setTotalFields,
    setCompletedFields,
    partOfSummary,
  } = props;

  const updateCompletedFieldCount = useCallback(() => {
    let completed = 0;
    if (data.amount && !errors.amount) completed++;
    if (data.creditDeadline >=0 && !errors.creditDeadline) completed++;
    if (data.currency && !errors.currency) completed++;
    if (data.resourceDestination && !errors.resourceDestination) completed++;
    setCompletedFields(completed);
  }, [data, errors, setCompletedFields]);

  const updateTotalFieldCount = useCallback(() => {
    let fieldCount = initialFieldCount;

    setTotalFields(fieldCount);
  }, [setTotalFields]);

  useEffect(() => {
    updateTotalFieldCount();
    updateCompletedFieldCount();
  }, [props, updateTotalFieldCount, updateCompletedFieldCount]);

  const changeData = (name, value, error) => {
    if (partOfSummary) {
      handleCustomChange(attribute, name, value, error);
    } else handleChange(name, value, error);
  };

  const handleAmount = (value) => {
    changeData('amount', value, false);
  };

  const handleCreditDeadline = (value) => {
    changeData('creditDeadline', value, false);
  };

  const handleSelect = (event) => {
    changeData(event.target.name, event.target.value, false);
  };

  const handleContent = (event) => {
    const error = event.target.value.length === 350;
    if (event.target.value.length <= 350) changeData(event.target.name, event.target.value, error);
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {!partOfSummary && (
          <MediaQuery maxDeviceWidth={760}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" align="center">
                Ingresa la información del crédito
              </Typography>
            </Grid>
          </MediaQuery>
        )}

      <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="none">
            <InputLabel>Destino de los recursos</InputLabel>
            <Select
              fullWidth
              value={data.resourceDestination || ''}
              name="resourceDestination"
              onChange={handleSelect}
              label="Destino de los recursos"
            >
              <MenuItem value={'Capital de trabajo'}>Capital de trabajo</MenuItem>
              <MenuItem value={'Modernización'}>Modernización</MenuItem>
              <MenuItem value={'Sustitución de pasivos'}>Sustitución de pasivos</MenuItem>
              <MenuItem value={'Comercio exterior'}>Comercio exterior</MenuItem>
              <MenuItem value={'Otro'}>Otro</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {!partOfSummary && (
            <Grid item xs={6}>
              <Grid container alignContent="space-between" justify="center" align="center">
                <Grid item>
                  <ResourceDestination handleClick={() => handleChange('resourceDestinationDialog', true, false)} />
                </Grid>
              </Grid>
            </Grid>
          )}
        {data.resourceDestination && (
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Describe el destino de tus recursos (opcional)"
              multiline
              rows="4"
              name="resourceDestinationDescription"
              error={errors.resourceDestinationDescription}
              helperText={
                errors.resourceDestinationDescription ? 'Ingresa una descripción de máximo 350 caracteres' : null
              }
              onChange={handleContent}
              value={data.resourceDestinationDescription || ''}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth margin="none">
            <InputLabel>¿En qué moneda lo deseas?</InputLabel>
            <Select
              fullWidth
              value={data.currency || ''}
              name="currency"
              onChange={handleSelect}
              label="¿En qué moneda lo deseas?"
            >
              <MenuItem value={'COP'}>COP</MenuItem>
              <MenuItem value={'USD'}>USD</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {!partOfSummary && (
          <Grid item xs={12} sm={12}>
            <AmountSlider onChange={handleAmount} value={data.amount} currency={data.currency || null} />
          </Grid>
        )}
        {!partOfSummary && (
          <Grid item xs={12} sm={12}>
            <DeadlineSlider onChange={handleCreditDeadline} value={data.creditDeadline}/>
          </Grid>
        )}
        
      </Grid>
    </React.Fragment>
  );
}
