import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import ValueLabel from "@material-ui/core/Slider/ValueLabel";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Colors from '../../../constants/Colors';
import CurrencyFormat from '../../common/CurrencyFormat';

function valuetext(value) {
  let amount = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  return amount.substring(0, amount.length - 3);
}

export default function AmountSlider({ onChange, value, currency, maximum, minimum }) {
  const classes = useStyles();

  const handleChange = (event, value) => {
    if (value >= 100000) {
      if (value <= 200000) onChange(Math.round((value) / 10000) * 10000)
      else if (value <= 1000000) onChange(Math.round((value) / 50000) * 50000)
      else if (value <= 3000000) onChange(Math.round((value) / 100000) * 100000)
      else if (value <= 5000000) onChange(Math.round((value) / 500000) * 500000)
      else if (value <= 30000000) onChange(Math.round((value) / 1000000) * 1000000)
      else if (value <= 150000000) onChange(Math.round((value) / 5000000) * 5000000)
      else onChange(Math.round((value) / 10000000) * 10000000)
    }
  }

  const handleInputChange = (event) => {
    onChange(event.target.value === '' ? '' : Number(event.target.value));
  };

  const getStep = () => {
    if (value) {
      if (currency && currency === 'USD') {
        if (value <= 5000) return 100;
        else if (value <= 50000) return 1000;
        else if (value <= 100000) return 5000;
        else return 10000;
      }
      else {
        if (value <= 200000) return 10000;
        else if (value <= 1000000) return 50000;
        else if (value <= 3000000) return 10000;
        else if (value <= 5000000) return 500000;
        else if (value <= 30000000) return 1000000;
        else if (value <= 150000000) return 5000000;
        else return 10000000;
      }
    }
    return 1000;
  }

  const getMaximum = () => {
    if (currency && currency === "USD") {
      if (maximum) {
        return Math.round((maximum / 4000) / 1000) * 1000;
      }
      return 150000;
    }
    else {
      if (maximum) {
        return maximum;
      }
      return 500000000;
    }
  }

  const getMinimum = () => {
    if (currency && currency === "USD") {
      if (minimum) {
        return Math.round((minimum / 4000) / 1000) * 1000;
      }
      return 50;
    }
    else {
      if (minimum) {

        return minimum;
      }
      return 1000000;
    }
  }

  return (
    <div className={classes.root}>
      <Grid container alignContent="space-between" justify="center" align="center">
        <Grid item xs={12} sm={6}>
          <Typography id="discrete-slider-restrict" className={classes.question}>
            ¿Cuánto dinero necesitas?
            </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.input}>
          <TextField
            
            label="Monto"
            autoComplete="off"
            inputProps={{
              min: getMinimum(),
              max: getMaximum(),
              inputMode: 'numeric',
              pattern: '[0-9]*'
            }}
            value={value}
            onChange={handleInputChange}
            InputProps={{
              inputComponent: CurrencyFormat,
            }}
          />
        </Grid>
      </Grid>

      <br />
      <br />
      <PrettoSlider
        min={getMinimum()}
        max={getMaximum()}
        valueLabelFormat={valuetext}
        getAriaValueText={valuetext}
        ValueLabelComponent={StyledValueLabel}
        step={getStep()}
        valueLabelDisplay="on"
        value={value || getMinimum()}
        onChange={handleChange}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '84%',
    margin: '0 8%'
  },
  question: {
    [theme.breakpoints.up('sm')]: {
      marginTop: '6%',
      textAlign: 'right',
      marginRight: '5%'
    },
  },
  input: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  }
}));

const StyledValueLabel = withStyles({
  circle: {
    backgroundColor: 'transparent',
    width: '80px',
    transform: 'rotate(0)'
  },
  label: {
    marginTop: '10px',
    fontWeight: 700,
    fontSize: '14px',
    color: Colors.primary,
    transform: 'rotate(0)'
  }
})(ValueLabel);

const PrettoSlider = withStyles({
  root: {
    color: Colors.primary,
    height: 8,
    maxWidth: '100%',
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: Colors.white,
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  track: {
    height: 8,
    borderRadius: 1,
  },
  rail: {
    height: 8,
    borderRadius: 1,
  },
})(Slider);