import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import ValueLabel from "@material-ui/core/Slider/ValueLabel";
import Colors from '../../../constants/Colors';

import { deadlines } from '../../../data/sliders';

function valuetext(index) {
  const value = deadlines[index].months;
  if (value<=1) return `${value*30} días`
  return `${value} meses`;
}

export default function DeadlineSlider({onChange, value, maximum, minimum}) {
  const classes = useStyles();

  const handleChange = (event, index) => {
    onChange(index)
  }

  const getMinimum = () => {
    if (minimum) {
      let minMonths = 1000;
      let minMatch = {};
      for (let deadline of deadlines) {
        if (deadline.months >= minimum && deadline.months < minMonths) {
          minMonths = deadline.months;
          minMatch = deadline;
        }
      }
      return minMatch.value;
    }
    return 0;
  }

  const getMaximum = () => {
    if (maximum) {
      let maxMonths = 0;
      let maxMatch = {};
      for (let deadline of deadlines) {
        if (deadline.months <= maximum && deadline.months > maxMonths) {
          maxMonths = deadline.months;
          maxMatch = deadline;
        }
      }
      return maxMatch.value;
    }
    return deadlines.length-1;
  }

  return (
    <div className={classes.root}>
      <Typography id="discrete-slider-restrict" gutterBottom style={{textAlign: 'center'}}>
        ¿En cuánto tiempo lo quieres pagar?
      </Typography>
      <br/>
      <PrettoSlider
        min={getMinimum()}
        max={getMaximum()}
        valueLabelFormat={valuetext}
        getAriaValueText={valuetext}
        ValueLabelComponent={StyledValueLabel}
        step={1}
        marks={deadlines.slice(getMinimum(), getMaximum()+1)}
        valueLabelDisplay="on"
        value={value || getMinimum()}
        onChange={handleChange}
      />
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    width: '84%',
    margin: '0 8%'
  },
});

const StyledValueLabel = withStyles({
  circle: {
    backgroundColor: 'transparent',
    width: '80px',
    transform: 'rotate(0)'
  },
  label: {
    marginTop: '10px',
    fontWeight: 700,
    fontSize: '14px',
    color: Colors.primary,
    transform: 'rotate(0)'
  }
})(ValueLabel);

const PrettoSlider = withStyles({
  root: {
    color: Colors.primary,
    height: 8,
    maxWidth: '100%',
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: Colors.white,
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  track: {
    height: 8,
    borderRadius: 1,
  },
  rail: {
    height: 8,
    borderRadius: 1,
  },
})(Slider);