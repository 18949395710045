import {
  Divider,
  Drawer,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Colors from '../../constants/Colors';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import MenuIcon from '@material-ui/icons/Menu';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const Header = () => {
  const classes = useStyles();
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const handleRedirect = () => {
    setRedirect(true);
  };

  const renderRedirect = () => {
    if (redirect) return <Redirect to="/pago" />;
  };

  return (
    <React.Fragment>
      {renderRedirect()}
      <Drawer
        scroll="no"
        anchor={'right'}
        elevation={0}
        open={openMobileMenu}
        onClose={() => setOpenMobileMenu(false)}
        PaperProps={{
          className: classes.drawer,
          style: {
            width: '100%',
            backgroundColor: Colors.primary,
            marginBottom: 'auto',
            height: '90vh',
            overflow: 'hidden'
          }
        }}
      >
        <List>
          <ListItem style={{ padding: 0 }}>
            <ListItem button component="button" onClick={() => handleRedirect()} style={{ padding: 0 }}>
              <ListItemText disableTypography className={classes.menuTitle} primary="Realizar pago" />
            </ListItem>
          </ListItem>
        </List>
      </Drawer>
      <AppBar
        position="absolute"
        color="primary"
        elevation={0}
        className={openMobileMenu ? classes.appBarMobile : classes.appBar}
      >
        <MediaQuery minDeviceWidth={801}>
          <Toolbar className={classes.topBar}>
            <div className={classes.header}>
              <Link to="/">
                <img
                  src={
                    window.location.hostname === 'colsubsidio.financialomejor.com'
                      ? 'https://cdn.financialomejor.com/img/bancos/colsubsidio.jpg'
                      : 'https://cdn.financialomejor.com/img/bancos/Bancatek.png'
                  }
                  alt="Logo Bancatek"
                  width="190"
                />
              </Link>
            </div>
            {window.location.hostname !== 'colsubsidio.financialomejor.com' && (
              <div className={classes.doPaymentContainer}>
                <Link to="/pago" className={classes.doPaymentLink}>
                  <Typography className={classes.doPayment}>Realizar pago</Typography>
                </Link>
              </div>
            )}
          </Toolbar>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={800}>
          <Toolbar className={classes.toolbarMobile}>
            <div className={classes.header}>
              <Link to="/">
                <img
                  src={
                    window.location.hostname === 'colsubsidio.financialomejor.com'
                      ? 'https://cdn.financialomejor.com/img/bancos/colsubsidio.jpg'
                      : 'https://cdn.financialomejor.com/img/bancos/Bancatek.png'
                  }
                  alt="Logo Bancatek"
                  width="190"
                />
              </Link>
            </div>
            {window.location.hostname !== 'colsubsidio.financialomejor.com' && (
              <div>
                {!openMobileMenu ? (
                  <div>
                    <Fade in={!openMobileMenu} timeout={600}>
                      <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        id="mobileMenuOpenButton"
                        onClick={() => setOpenMobileMenu(true)}
                      >
                        <MenuIcon />
                      </IconButton>
                    </Fade>
                  </div>
                ) : (
                  <div className={classes.containerMobile}>
                    <IconButton
                      edge="start"
                      className={classes.menuButtonClose}
                      color="inherit"
                      aria-label="menu"
                      id="mobileMenuCloseButton"
                      onClick={() => setOpenMobileMenu(false)}
                    >
                      X
                    </IconButton>
                    <Divider light={true} />
                  </div>
                )}
              </div>
            )}
          </Toolbar>
        </MediaQuery>
      </AppBar>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  topBar: {
    height: theme.spacing(12),
    margin: 'auto'
  },
  header: {
    width: '70vw',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  doPayment: {
    color: 'white',
    padding: '8%'
  },
  doPaymentMobile: {
    color: Colors.secondary
  },
  doPaymentLink: {
    textDecoration: 'none'
  },
  doPaymentContainer: {
    backgroundColor: Colors.secondary,
    borderRadius: '25px',
    width: '150px',
    height: '50px',
    textAlign: 'center'
  },
  appBarMobile: {
    height: '100vh'
  },
  menuButton: {
    color: Colors.primary,
    backgroundColor: Colors.secondary,
    position: 'absolute',
    top: '7%',
    right: '2%',
    bottom: 'auto'
  },
  menuButtonClose: {
    position: 'absolute',
    top: '12%',
    right: '2%',
    bottom: 'auto',
    padding: '3% 5%'
  },
  containerMobile: {
    height: '100%'
  },
  drawer: {
    marginTop: theme.spacing(8)
  },
  menuTitle: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '17px',
    color: Colors.secondary,
    padding: '2% 0 0',
    margin: 0,
    textAlign: 'center'
  }
}));

export default Header;
