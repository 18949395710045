import Button from '@material-ui/core/Button';
import React from 'react';
import ResourseDestinationDialog from './creditInfo/ResourceDestinationDialog';
import InfoIcon from '@material-ui/icons/Info';

export default function ResourceDestination(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    props.handleClick(true);
    setOpen(false);
  };

  return (
    <div style={{ display: "inline-block"}}>
      <div style={{fontSize: "0.85rem", marginTop:"5px"}}>
      <Button color="primary" onClick={handleClickOpen}>
        <InfoIcon/> VER EXPLICACIÓN Destino de los recursos
      </Button></div>
      <ResourseDestinationDialog open={open} handleClose={handleClose} />
    </div>
  );
}
