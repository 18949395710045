const lineruInstalmentCalculation = (amount, deadlineMonths) => {
  const days = Math.round(30 * deadlineMonths);
  const insurance = amount * 0.0045;
  const administration = 16000;
  const technology = 1000 * days;
  const interest = (Math.pow(1.25, days / 365) - 1) * amount;
  const iva = 0.19 * (technology + administration);
  const components = {insurance, administration, technology, interest, iva};
  let result = amount + insurance + administration + technology + interest + iva;
  return {result, components};
};

const rapicreditInstalmentCalculation = (amount, deadlineMonths) => {
  const days = Math.round(30 * deadlineMonths);
  const insurance = 14791 + ((amount - 110000) / 10000) * 1345 + 0.00119 * amount * (days - 5);
  const technology = 1250 * days;
  const interest = (Math.pow(1.25, days / 365) - 1) * amount;
  const iva = 0.19 * technology;
  const components = {insurance, technology, interest, iva};
  let result = amount + insurance + technology + interest + iva;
  return {result, components};
};

const technologyCostMapKredicity = {
  '5': 17500,
  '15': 39345,
  '30': 49200,
};

const kredicityInstalmentCalculation = (amount, deadlineMonths) => {
  const days = Math.round(30 * deadlineMonths);
  const technology = technologyCostMapKredicity[`${days}`];
  const interest = (Math.pow(1.248, days / 365) - 1) * amount;
  const iva = 0.19 * technology;
  const components = {technology, interest, iva};
  let result = amount + technology + interest + iva;
  return {result, components}
};

const bancatekInstalmentCalculation = (amount, deadlineMonths, rateOver100) => {
  const rate = rateOver100 / 100;
  const monthlyRate = Math.pow(1 + rate, 1 / 12) - 1;
  const days = Math.round(30 * deadlineMonths);//
  const administration = 25000;
  let interest = (Math.pow(1 + rate, days / 360) - 1) * amount;
  //const insurance = 0.45 / 100 * amount;
  const technology = deadlineMonths * administration;
  let iva = 0.19 * technology;
  let result, components;
  if (deadlineMonths <= 1) {
    components = {
      //insurance,
      administration, technology, interest, iva
    };
    result = amount + 
    //insurance +
    administration + technology + interest + iva;
  } else {
    iva = 0.19 * (technology + administration);
    components = {
      //insurance: insurance/deadlineMonths,
      administration: administration/deadlineMonths,
      technology: technology/deadlineMonths,
      iva: iva/deadlineMonths,
    };
    result = PMT(monthlyRate, deadlineMonths, -amount) + (
      //insurance +
      administration + technology + iva
      ) / deadlineMonths;
  }
  return {result, components};
};

export default {
  lineruInstalmentCalculation,
  rapicreditInstalmentCalculation,
  kredicityInstalmentCalculation,
  bancatekInstalmentCalculation
};

function PMT(ir, np, pv, fv, type) {
  /*
   * ir   - interest rate per month
   * np   - number of periods (months)
   * pv   - present value
   * fv   - future value
   * type - when the payments are due:
   *        0: end of the period, e.g. end of month (default)
   *        1: beginning of period
   */
  var pmt, pvif;

  fv || (fv = 0);
  type || (type = 0);

  if (ir === 0)
      return -(pv + fv)/np;

  pvif = Math.pow(1 + ir, np);
  pmt = - ir * pv * (pvif + fv) / (pvif - 1);

  if (type === 1)
      pmt /= (1 + ir);

  return pmt;
}